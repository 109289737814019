import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import BarChartTemplate from "views/molecules/charts/template/BarChartTemplate"

const ChartODD13ScopePerSubScopeBreakdownMolecule = ({
  score,
  scope,
  methodology,
  helpText,
  based,
}) => {
  const { t } = useTranslation()

  const getDatasets = (score, methodology, scope, based) => {
    const datasets =
      score[`env_score_${methodology}_${based}`][`${scope}_datasets`]
    const data = datasets.map((data) => data.data)
    const labelKeys = datasets.map((data) => data.label)
    const unitKey = datasets[0].unit

    return { data, labelKeys, unitKey }
  }

  const { data, labelKeys, unitKey } = getDatasets(
    score,
    methodology,
    scope,
    based
  )

  return (
    <BarChartTemplate
      title={t(
        `analyze:ghg_scopes.chartODD13ScopePerSubScopeBreakdownMolecule.title.${scope}`
      )}
      data={data}
      labelPath={
        "analyze:ghg_scopes.chartODD13ScopePerSubScopeBreakdownMolecule.label_keys"
      }
      labelKeys={labelKeys}
      unitPath={"units:units.emission"}
      unitKey={unitKey}
      helpText={helpText}
    />
  )
}

ChartODD13ScopePerSubScopeBreakdownMolecule.propTypes = {
  score: PropTypes.object,
  methodology: PropTypes.oneOf(["ghg_protocol", "bilan_carbone"]).isRequired,
  scope: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired,
  based: PropTypes.oneOf(["market_based", "location_based"]).isRequired,
}

export default ChartODD13ScopePerSubScopeBreakdownMolecule
