import ParentSelectionField from "components/widgets/Dashboard/OrganizationChart/ParentSelectionField"
import TextFieldAtom from "views/atoms/organization/form/TextFieldAtom"
import CountryNameFieldAtom from "views/atoms/organization/form/CountryNameFieldAtom"
import DateFieldAtom from "views/atoms/organization/form/DateFieldAtom"
import NumberUnitFieldAtom from "views/atoms/organization/form/NumberUnitFieldAtom"
import NumberFieldAtom from "views/atoms/organization/form/NumberFieldAtom"
import SiteAssessmentFieldAtom from "views/atoms/organization/form/SiteAssessmentFieldAtom"
import {
  NODE_TYPE_BRAND,
  NODE_TYPE_COMPANY,
  NODE_TYPE_COUNTRY,
  NODE_TYPE_COUNTRY_TEAM,
  NODE_TYPE_PARTNER,
  NODE_TYPE_PHASE,
  NODE_TYPE_PROGRAM,
  NODE_TYPE_PROJECT,
  NODE_TYPE_REGION,
  NODE_TYPE_SERVICE,
  NODE_TYPE_SERVICE_COMPONENT,
  NODE_TYPE_SITE,
} from "utils/constants/organization/nodeTypes"
import SectorFieldAtom from "views/atoms/organization/form/SectorFieldAtom"
import TextAreaFieldAtom from "views/atoms/organization/form/TextAreaFieldAtom"
import CheckboxFieldAtom from "views/atoms/organization/form/CheckboxFieldAtom"
import PartnerCreationBannerAtom from "views/atoms/organization/form/custom/PartnerCreationBannerAtom"

export const FORM_FIELDS_TO_COMPONENTS = {
  parent: ParentSelectionField,
  name: TextFieldAtom,
  country_name: CountryNameFieldAtom,
  client: TextFieldAtom,
  project_id: TextFieldAtom,
  scenario_name: TextFieldAtom,
  description: TextAreaFieldAtom,
  business_unit: TextAreaFieldAtom,
  starts_on: DateFieldAtom,
  scenario_starts_on: DateFieldAtom,
  ends_on: DateFieldAtom,
  scenario_ends_on: DateFieldAtom,
  budget_unit: NumberUnitFieldAtom,
  budget_formatted: NumberFieldAtom,
  revenue_unit: NumberUnitFieldAtom,
  revenue_formatted: NumberFieldAtom,
  site_assessment: SiteAssessmentFieldAtom,
  team_member_count: NumberFieldAtom,
  headquarters_country: CountryNameFieldAtom,
  sector: SectorFieldAtom,
  number_country_operation: NumberFieldAtom,
  number_of_sites: NumberFieldAtom,
  employees: NumberFieldAtom,
  digital_employees: NumberFieldAtom,
  shared_with_client: CheckboxFieldAtom,
  involvement_duration_in_month: NumberFieldAtom,
  partner_creation_banner: PartnerCreationBannerAtom,
  version_name: TextFieldAtom,
  version_description: TextAreaFieldAtom,
  workload: NumberFieldAtom,
}

export const HIDDEN_FIELDS = ["light"]

export const HIDDEN_FIELDS_FOR_NO_PROVIDER = [
  "version_name",
  "version_description",
]

export const HIDDEN_CREATE_FIELDS = ["parent", ...HIDDEN_FIELDS]

export const NODE_TYPE_TO_STANDARD_FORM_FIELDS = {
  [NODE_TYPE_PROGRAM]: [
    "name",
    "version_name",
    "version_description",
    ["starts_on", "ends_on"],
    ["budget_formatted", "budget_unit"],
    ["revenue_formatted", "revenue_unit"],
    "team_member_count",
    "workload",
  ],
  [NODE_TYPE_PROJECT]: [
    "name",
    "version_name",
    "version_description",
    ["starts_on", "ends_on"],
    ["budget_formatted", "budget_unit"],
    ["revenue_formatted", "revenue_unit"],
    "team_member_count",
    "workload",
  ],
  [NODE_TYPE_PHASE]: [
    "parent",
    "name",
    "version_name",
    "version_description",
    ["starts_on", "ends_on"],
    ["budget_formatted", "budget_unit"],
    "team_member_count",
    "workload",
  ],
  [NODE_TYPE_COUNTRY_TEAM]: [
    "parent",
    "country_name",
    "version_name",
    "version_description",
    ["starts_on", "ends_on"],
    ["budget_formatted", "budget_unit"],
    "team_member_count",
    "workload",
  ],
  [NODE_TYPE_COMPANY]: [
    "name",
    ["budget_formatted", "budget_unit"],
    ["revenue_formatted", "revenue_unit"],
    "employees",
    "digital_employees",
    "headquarters_country",
    "sector",
    "number_country_operation",
  ],
  [NODE_TYPE_BRAND]: [
    "parent",
    "name",
    ["budget_formatted", "budget_unit"],
    ["revenue_formatted", "revenue_unit"],
    "employees",
    "digital_employees",
    "headquarters_country",
    "number_country_operation",
  ],
  [NODE_TYPE_REGION]: [
    "parent",
    "name",
    ["budget_formatted", "budget_unit"],
    ["revenue_formatted", "revenue_unit"],
    "employees",
    "digital_employees",
    "number_country_operation",
  ],
  [NODE_TYPE_COUNTRY]: [
    "parent",
    "country_name",
    ["budget_formatted", "budget_unit"],
    "employees",
    "digital_employees",
    "number_of_sites",
    "site_assessment",
  ],
  [NODE_TYPE_SITE]: [
    "parent",
    "name",
    ["budget_formatted", "budget_unit"],
    "employees",
    "digital_employees",
  ],
  [NODE_TYPE_SERVICE]: [
    "name",
    "version_name",
    "version_description",
    ["starts_on", "ends_on"],
    ["budget_formatted", "budget_unit"],
    ["revenue_formatted", "revenue_unit"],
    "team_member_count",
    "workload",
  ],
  [NODE_TYPE_SERVICE_COMPONENT]: [
    "parent",
    "name",
    "version_name",
    "version_description",
    ["starts_on", "ends_on"],
    ["budget_formatted", "budget_unit"],
    "team_member_count",
    "workload",
  ],
  [NODE_TYPE_PARTNER]: [
    "name",
    "version_name",
    "version_description",
    ["budget_formatted", "budget_unit"],
    "partner_creation_banner",
  ],
}

const lightFields = ["name", "light"]
const lightCompanyFields = ["name", "sector", "light"]

export const PROVIDER_CORPORATE_NODE_TYPE_TO_FORM_FIELDS = {
  [NODE_TYPE_COMPANY]: lightCompanyFields,
  [NODE_TYPE_BRAND]: lightFields,
  [NODE_TYPE_REGION]: lightFields,
  [NODE_TYPE_COUNTRY]: ["country_name", "light"],
  [NODE_TYPE_SITE]: lightFields,
}

export const PROVIDER_NODE_TYPE_TO_FORM_FIELDS = {
  ...NODE_TYPE_TO_STANDARD_FORM_FIELDS,
  ...PROVIDER_CORPORATE_NODE_TYPE_TO_FORM_FIELDS,
}

export const CREATE_PROVIDER_NODE_TYPE_TO_FORM_FIELDS = {
  ...NODE_TYPE_TO_STANDARD_FORM_FIELDS,
  ...PROVIDER_CORPORATE_NODE_TYPE_TO_FORM_FIELDS,
  [NODE_TYPE_COMPANY]: [...lightCompanyFields],
}

export const FORM_FIELDS_TO_PREFILL = [
  "client",
  "starts_on",
  "ends_on",
  "version_name",
  "version_description",
]

export const FORM_MODE_CREATE = "create"
export const FORM_MODE_EDIT = "edit"

export const PARENT_FIELD = "parent"
export const CENTRALIZATION_FIELD = "centralization"
