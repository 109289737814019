import React from "react"
import { useTranslation } from "react-i18next"
import { HorizontalBar } from "react-chartjs-2"
import { ChartContainer } from "views/organisms/organization/tables/ChartContainer"
import PropTypes from "prop-types"
import useTranslatedLabels from "services/charts/translatedLabels"
import { getFormattedDatasets } from "services/charts/datasets"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { dataLabelsPlugin } from "services/charts/plugins/datalabeles"
import { convertValuesToReadable } from "services/units/valueConversion"
import { DETERMINE_UNITS } from "services/units/units"

const HorizontalCumulativeBarChartTemplate = ({
  title,
  datasets,
  labelPath,
  labelKeys,
  unitPath,
  unitKey,
  helpText,
  pillar,
  showUnitOnBar,
  showLabelsOnBar,
  hiddenLegend,
  useBackgroundColorsInDatasets,
  addOtherCategory,
}) => {
  const { t } = useTranslation()
  const formattedDatasets = getFormattedDatasets({
    datasets,
    useBackgroundColorsInDatasets,
    labelKeys,
    addOtherCategory,
  })
  const translatedLabels = useTranslatedLabels(labelPath, labelKeys)

  if (DETERMINE_UNITS(unitKey)) {
    const convertedValue = convertValuesToReadable(
      formattedDatasets.map((dataset) => dataset.data),
      unitKey,
      DETERMINE_UNITS(unitKey)
    )

    formattedDatasets.forEach((dataset, index) => {
      dataset.data = convertedValue.values[index]
    })

    unitKey = convertedValue.unit
  }

  formattedDatasets.forEach((dataset) => {
    dataset.datalabelUnit = t(`${unitPath}.${unitKey}`)
    dataset.showUnitOnDatalabel = true
  })

  const data = {
    labels: translatedLabels,
    datasets: formattedDatasets,
  }

  const options = {
    ...DEFAULT_OPTIONS,
    scales: {
      xAxes: [
        {
          stacked: true,
          display: true,
          position: "right",
          scaleLabel: {
            display: true,
            labelString: t(`${unitPath}.${unitKey}`),
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [{ stacked: true }],
    },
  }

  if (showUnitOnBar || showLabelsOnBar) {
    options.plugins = {
      ...dataLabelsPlugin(
        showUnitOnBar ? t(`${unitPath}.${unitKey}`) : undefined,
        showLabelsOnBar
          ? formattedDatasets.map(({ label }) => label)
          : undefined
      ),
    }
  }

  if (hiddenLegend) {
    options.legend = {
      display: false,
    }
  }

  return (
    <ChartContainer title={title} helpText={helpText} pillar={pillar}>
      <HorizontalBar data={data} options={options} />
    </ChartContainer>
  )
}

export default HorizontalCumulativeBarChartTemplate

HorizontalCumulativeBarChartTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  datasets: PropTypes.array.isRequired,
  labelPath: PropTypes.string,
  labelKeys: PropTypes.array.isRequired,
  unitPath: PropTypes.string,
  unitKey: PropTypes.string,
  useBackgroundColorsInDatasets: PropTypes.bool,
  pillar: PropTypes.string,
  showUnitOnBar: PropTypes.bool,
  showLabelsOnBar: PropTypes.bool,
  hiddenLegend: PropTypes.bool,
  addOtherCategory: PropTypes.bool,
}

HorizontalCumulativeBarChartTemplate.defaultProps = {
  title: "",
  helpText: "",
  unitPath: "",
  unitKey: "",
  useBackgroundColorsInDatasets: false,
  pillar: "financial",
  showUnitOnBar: false,
  addOtherCategory: false,
}
