import { useTranslation } from "react-i18next"

const useTranslatedLabels = (labelPath, labelKeys) => {
  const { t } = useTranslation()

  return (
    labelKeys?.map((label) => {
      if ([undefined, null, "undefined"].includes(label)) {
        return t("common:undefined")
      }

      if (!labelPath) {
        if (label === "other") {
          return t("common:other")
        }

        return label
      }
      return t(`${labelPath}.${label}`)
    }) || []
  )
}

export default useTranslatedLabels
