import React, { useCallback } from "react"
import ITDomainAnalysisLayoutOrganism from "views/organisms/canvases/charts/ITDomainAnalysisLayoutOrganism"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import Unit from "views/atoms/reports/Unit"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import { EMISSION_UNIT } from "utils/constants/numbers"
import _ from "lodash"
import Config from "assets/configs/score"
import {
  KPI_STATUS_DISABLED,
  KPI_STATUS_DOWN,
  KPI_STATUS_NEUTRAL,
  KPI_STATUS_UP,
} from "utils/constants/analyze/kpi/kpiStatus"
import { round } from "services/units/round"
import { compareNodeLabels } from "services/score/charts/labels/compareNodeLabels"
import ChartRadarMolecule from "views/molecules/canvases/charts/common/ChartRadarMolecule"
import { chart4Options } from "views/organisms/canvases/charts/analyzes/historization/HistorizationAnalyzeChartsOrganism"
import BarChartTemplate from "views/molecules/charts/template/BarChartTemplate"
import { COMPARE_LEGENDS } from "services/charts/legend"
import CumulativeBarChartTemplate from "views/molecules/charts/template/CumulativeBarChartTemplate"

const prefixTranslation = "analyze:compare."

const chart3statusColors = {
  [KPI_STATUS_UP]: Config.common.colorsEvaluation.green,
  [KPI_STATUS_NEUTRAL]: Config.common.colorsEvaluation.yellow2,
  [KPI_STATUS_DOWN]: Config.common.colorsEvaluation.red,
  [KPI_STATUS_DISABLED]: Config.common.disabledColor,
}
const chart3statusColor = (status) => chart3statusColors[status]

const colors = {
  lowest: {
    backgroundColor: "rgba(100, 164, 108, 0.5)",
    borderColor: Config.common.colorsEvaluation.green,
    pointBackgroundColor: Config.common.colorsEvaluation.green,
  },
  medium: {
    backgroundColor: "rgba(255, 218, 32, 0.5)",
    borderColor: Config.common.colorsEvaluation.yellow,
    pointBackgroundColor: Config.common.colorsEvaluation.yellow,
  },
  highest: {
    backgroundColor: "rgba(199, 0, 57, 0.5)",
    borderColor: Config.common.colorsEvaluation.red,
    pointBackgroundColor: Config.common.colorsEvaluation.red,
  },
}

const colorByPosition = (hasFirstPosition, hasLastPosition, hasOneNode) => {
  if (hasFirstPosition) return colors.lowest
  if (hasLastPosition && hasOneNode) return colors.medium
  if (hasLastPosition) return colors.highest
  return colors.medium
}

const SimpleNodeAnalysesOrganism = ({ comparedScores, comparedNodes }) => {
  const { t } = useTranslation()

  const hasOneNode = comparedNodes.length === 1

  const chart1 = useCallback(() => {
    const { datasets, unit_key } = comparedScores.chart1

    const data = datasets.map(({ value }) => {
      return value
    })
    const label = datasets.map(({ name }) => compareNodeLabels(name))

    if (!datasets.length) return {}

    return {
      data,
      labelKeys: label,
      unitKey: unit_key,
    }
  }, [comparedScores])

  const chart2 = useCallback(() => {
    const { labels, datasets } = comparedScores.chart2

    const maturities = datasets
      .map(({ maturity }) => +maturity)
      .sort()
      .reverse()

    const cleaned_maturities = _.uniq(maturities)

    const formattedDatasets = datasets.map(({ label, data, maturity }) => {
      const position = cleaned_maturities.findIndex((m) => m === +maturity)
      const hasFirstPosition = position === 0
      const hasLastPosition = position === cleaned_maturities.length - 1

      return {
        label: compareNodeLabels(label),
        data,
        ...colorByPosition(hasFirstPosition, hasLastPosition, hasOneNode),
      }
    })

    return {
      labels,
      datasets: formattedDatasets,
    }
  }, [comparedScores])

  const kp1 = (
    <TextChart
      content={
        <TextChartMajor
          content={{
            kpi: compareNodeLabels(comparedScores.kpi1.name),
            kpi_text: t(prefixTranslation + "charts.kpi1.title"),
          }}
        />
      }
    />
  )

  const kpi2 = (
    <TextChart
      content={
        <TextChartMinor
          content={{
            kpi: (
              <>
                {compareNodeLabels(comparedScores.kpi2.name)}
                <br /> {comparedScores.kpi2.delta}
              </>
            ),
            kpi_unit: "%",
            kpi_text: t(
              prefixTranslation + "charts.kpi2.lowest_ghg_emission_node.title"
            ),
            reference: round(comparedScores.kpi2.value.value),
            reference_unit: (
              <>
                &nbsp;
                <Unit
                  unit={NumberFormatterApp.getI18nUnit(
                    EMISSION_UNIT,
                    comparedScores.kpi2.value.unit
                  )}
                />
              </>
            ),
          }}
        />
      }
    />
  )

  const kpi3 = (
    <TextChart
      content={
        <TextChartMinor
          content={{
            kpi: (
              <>
                {compareNodeLabels(comparedScores.kpi3.name)}
                <br />
                {comparedScores.kpi3.delta >= 0 ? "+" : "-"}
                {comparedScores.kpi3.delta}
              </>
            ),
            kpi_text: t(
              prefixTranslation + "charts.kpi3.best_maturity_node.title"
            ),
            reference: `${comparedScores.kpi3.maturity} / 100`,
          }}
        />
      }
    />
  )

  const dataChart4 = comparedScores.chart3.datasets.map((datasets) => {
    const status = datasets.data.statuses.map((status) => {
      return chart3statusColor(status)
    })
    return {
      label: t(
        `score:score.13.chartGHGEmissionsDistribution.${datasets.data.type}`
      ),
      data: datasets.data.emissions.map((value) => round(value)),
      unit: comparedScores.chart3.unit,
      backgroundColor: status,
    }
  })

  const labelChart4 = comparedScores.chart3.labels.map((label) => {
    return compareNodeLabels(label)
  })

  return (
    <ITDomainAnalysisLayoutOrganism
      pillar={PILLAR_ENVIRONMENTAL}
      transSuffix={`${prefixTranslation}charts.`}
      chart1={kp1}
      chart2={kpi2}
      chart3={
        <BarChartTemplate
          title={t(prefixTranslation + "charts.chart1.label")}
          {...chart1()}
          compareBarColors
          unitPath={"units:units.emission"}
          helpText={t(prefixTranslation + "charts.chart1.help")}
        />
      }
      chart4={
        <CumulativeBarChartTemplate
          title={t(prefixTranslation + "charts.chart3.label")}
          datasets={dataChart4}
          legendLabels={COMPARE_LEGENDS}
          showLabelOnBar
          labelKeys={labelChart4}
          unitPath="units:units.emission"
          unitKey={comparedScores.chart3.unit}
          helpText={t(prefixTranslation + "charts.chart3.help")}
          useBackgroundColorsInDatasets
          compareBarColors
          modifiedLegend={COMPARE_LEGENDS}
        />
      }
      chart5={kpi3}
      chart6={
        <ChartRadarMolecule
          title={t(prefixTranslation + "charts.chart2.label")}
          config={chart2()}
          options={chart4Options}
          pillarId={PILLAR_ENVIRONMENTAL}
        />
      }
    />
  )
}

SimpleNodeAnalysesOrganism.propTypes = {
  comparedScores: PropTypes.object.isRequired,
  comparedNodes: PropTypes.array,
}

SimpleNodeAnalysesOrganism.defaultProps = {
  disabled: false,
  comparedNodes: [],
}

export default SimpleNodeAnalysesOrganism
