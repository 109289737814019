import React from "react"
import { useTranslation } from "react-i18next"
import { Bar } from "react-chartjs-2"
import useTranslatedLabels from "services/charts/translatedLabels"
import { dataLabelsPlugin } from "services/charts/plugins/datalabeles"
import { stackedAxes } from "services/charts/axes"
import { getFormattedDatasets } from "services/charts/datasets"
import averagesDatasets from "services/charts/averagesDatasets"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { BOTTOM_LEGEND } from "services/charts/legend"
import { ChartContainer } from "views/organisms/organization/tables/ChartContainer"
import PropTypes from "prop-types"
import { convertValuesToReadable } from "services/units/valueConversion"
import { DETERMINE_UNITS } from "services/units/units"

const AvgCumulativeBarChartTemplate = ({
  title,
  datasets,
  colorsBarComparative,
  referenceTitle,
  referenceData,
  labelPath,
  labelKeys,
  unitPath,
  unitKey,
  helpText,
  useBackgroundColorsInDatasets,
  addOtherCategory,
}) => {
  const { t } = useTranslation()
  const formattedDatasets = getFormattedDatasets({
    datasets,
    colorsBarComparative,
    referenceData,
    useBackgroundColorsInDatasets,
    labelKeys,
  })

  if (DETERMINE_UNITS(unitKey)) {
    const convertedValue = convertValuesToReadable(
      formattedDatasets.map((dataset) => dataset.data.map((value) => +value)),
      unitKey,
      DETERMINE_UNITS(unitKey)
    )

    formattedDatasets.forEach((dataset, index) => {
      console.log({ convertedValue: convertedValue.values[index] })
      dataset.data = convertedValue.values[index]
    })

    unitKey = convertedValue.unit
  }

  const translatedLabels = useTranslatedLabels(
    labelPath,
    formattedDatasets[0].labelKeys || labelKeys
  )

  const defaultReference = t(`score:score.common.datasets.market_data`)

  const data = {
    labels: translatedLabels,
    datasets: [
      ...formattedDatasets,
      averagesDatasets(
        defaultReference,
        referenceTitle,
        referenceData?.map((value) => Number(value))
      ),
    ],
  }

  const options = {
    ...DEFAULT_OPTIONS,
    legend: BOTTOM_LEGEND,
    scales: stackedAxes(t(`${unitPath}.${unitKey}`)),
    plugins: dataLabelsPlugin(),
  }

  return (
    <ChartContainer title={title} helpText={helpText}>
      <Bar data={data} options={options} />
    </ChartContainer>
  )
}

export default AvgCumulativeBarChartTemplate

AvgCumulativeBarChartTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  datasets: PropTypes.array.isRequired,
  referenceTitle: PropTypes.string,
  referenceData: PropTypes.array.isRequired,
  labelPath: PropTypes.string,
  labelKeys: PropTypes.array.isRequired,
  unitPath: PropTypes.string.isRequired,
  unitKey: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  useBackGroundColor: PropTypes.bool,
  colorsBarComparative: PropTypes.bool,
  useBackgroundColorsInDatasets: PropTypes.bool,
  addOtherCategory: PropTypes.bool,
}

AvgCumulativeBarChartTemplate.defaultProps = {
  referenceTitle: "",
  helpText: "",
  labelPath: "",
  useBackGroundColor: false,
  colorsBarComparative: false,
  useBackgroundColorsInDatasets: false,
  addOtherCategory: false,
}
