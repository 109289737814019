import React from "react"
import PropTypes from "prop-types"
import CountryApp from "services/apps/countryApp"
import Config from "assets/configs/score.js"
import { useTranslation } from "react-i18next"
import { PILLAR_ENVIRONMENTAL, PILLARS } from "utils/constants/pillars"
import { KPI_STATUS_GHG_TREND_TO_COLOR } from "utils/constants/analyze/kpi/kpiStatus"
import i18n from "i18next"
import { COLORS } from "utils/constants/colors"
import {
  convertValuesToUnit,
  determineUnit,
} from "services/units/valueConversion"
import { CO2_EMISSION_UNITS } from "services/units/units"
import CumulativeBarChartTemplate from "views/molecules/charts/template/CumulativeBarChartTemplate"
import { COMPARE_LEGENDS_DECREASE_AND_INCREASE } from "services/charts/legend"

const ChartBarStackedGroupedMolecule = ({
  title,
  pillarId,
  config,
  statusColor,
  help,
}) => {
  const { t } = useTranslation()

  const { datasets, labels, unit_key } = config

  const getUnit = determineUnit(
    datasets
      .map((dataset) => dataset.data.emissions)
      .filter((value) => value !== 0),
    unit_key,
    CO2_EMISSION_UNITS
  )

  const buildDatasets = datasets.map((dataset) => {
    const defaultBackgroundColors = new Array(dataset.data.values.length).fill(
      Config["13"].chartGHGEmissionsDistribution.colors[dataset.data.type]
    )

    const convertedData = convertValuesToUnit(
      dataset.data.emissions.filter((value) => value !== 0),
      unit_key,
      getUnit,
      CO2_EMISSION_UNITS
    )

    return {
      data: convertedData.values,
      label: t(
        `score:score.13.chartGHGEmissionsDistribution.${dataset.data.type}`
      ),
      backgroundColor: dataset.data.statuses
        ? dataset.data.statuses.map(statusColor)
        : defaultBackgroundColors,
    }
  })

  return (
    <CumulativeBarChartTemplate
      title={title}
      datasets={buildDatasets}
      labelKeys={labels}
      unitPath="units:units.emission"
      unitKey={unit_key}
      pillar={pillarId}
      useBackgroundColorsInDatasets
      showLabelOnBar
      helpText={help}
      modifiedLegend={COMPARE_LEGENDS_DECREASE_AND_INCREASE}
    />
  )
}

ChartBarStackedGroupedMolecule.propTypes = {
  config: PropTypes.object,
  title: PropTypes.string,
  pillarId: PropTypes.oneOf(PILLARS).isRequired,
  labels: PropTypes.func,
  statusColor: PropTypes.func,
}

ChartBarStackedGroupedMolecule.defaultProps = {
  title: "",
  pillarId: PILLAR_ENVIRONMENTAL,
  statusColor: (status) => KPI_STATUS_GHG_TREND_TO_COLOR[status],
}

export default ChartBarStackedGroupedMolecule
