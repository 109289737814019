import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import Unit from "views/atoms/reports/Unit"
import { convertValueToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS } from "services/units/units"
import { round } from "services/units/round"
import { T_UNIT } from "utils/constants/numbers"

const TextChartODD13ScopeMolecule = ({
  score,
  scope1,
  scope2 = "",
  methodology,
  based,
}) => {
  const { t } = useTranslation()
  const odd13CO2Switcher = (scope) => {
    switch (scope) {
      case "scope2":
        return score?.[`env_score_${methodology}_${based}`]?.kpi_scope_2
      case "scope3":
        return score?.[`env_score_${methodology}_${based}`]?.kpi_scope_3
      case "":
        return null
    }
  }
  const formatContent = (scope) => {
    const data = odd13CO2Switcher(scope)

    const convertedData = convertValueToReadable(
      data || 0,
      T_UNIT,
      CO2_EMISSION_UNITS
    )

    const displayedUnit = (
      <Unit
        unit={NumberFormatterApp.getI18nUnit("emission", convertedData.unit)}
      />
    )

    return {
      kpi: round(convertedData.value),
      kpi_unit: displayedUnit,
      kpi_text: t(
        `analyze:ghg_scopes.textChartODD13ScopeMolecule.title.${scope}`
      ),
    }
  }

  const content1 = formatContent(scope1)
  const content2 = scope2 !== "" ? formatContent(scope2) : null

  const content_for_display = (
    <div>
      <TextChartMinor content={content1} />
      {content2 && <TextChartMinor content={content2} />}
    </div>
  )
  return <TextChart content={content_for_display} />
}

TextChartODD13ScopeMolecule.propTypes = {
  score: PropTypes.object.isRequired,
  scope1: PropTypes.string.isRequired,
  scope2: PropTypes.string,
  referenceScope1: PropTypes.string,
  referenceScope2: PropTypes.string,
  methodology: PropTypes.oneOf(["bilan_carbone", "ghg_protocol"]).isRequired,
  based: PropTypes.oneOf(["market_based", "location_based"]).isRequired,
}

export default TextChartODD13ScopeMolecule
