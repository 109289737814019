import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import Unit from "views/atoms/reports/Unit"
import { round } from "services/units/round"
import { convertValueToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS, T_CO2E } from "services/units/units"

const TextChartODD13GHGEmissionsLocalDataRoomMolecule = ({ score }) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."

  const data = score?.odd13_internal_hosting_ghg_emissions_average || {}

  const convertedValue = convertValueToReadable(
    data?.median_small?.total,
    T_CO2E,
    CO2_EMISSION_UNITS
  )

  const content = {
    kpi: round(convertedValue.value),
    kpi_unit: t(`units:units.emission.${convertedValue.unit}`),
    kpi_text: t(`${translationPrefix}chartGHGEmissionsLocalDataRoom.text`),
    reference: "*" + data?.median_small?.average,
    reference_unit: (
      <Unit
        unit={t(
          `${translationPrefix}chartGHGEmissionsLocalDataRoom.reference_unit`
        )}
      />
    ),
    reference_text: t(
      `${translationPrefix}chartGHGEmissionsLocalDataRoom.subtext`
    ),
  }

  const content_for_display = <TextChartMinor content={content} />

  return <TextChart content={content_for_display} />
}

TextChartODD13GHGEmissionsLocalDataRoomMolecule.propTypes = {
  score: PropTypes.object,
}

export default TextChartODD13GHGEmissionsLocalDataRoomMolecule
