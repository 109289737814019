import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import CumulativeBarChartTemplate from "views/molecules/charts/template/CumulativeBarChartTemplate"

const ChartODD13GHGEmissionsDistributionConsolidateMolecule = ({ score }) => {
  const { t } = useTranslation()

  const { datasets, label_keys, unit } = score.odd13_emissions_breakdown_consolidated

  const data = datasets.map((dataset) => ({
    label: NodeOrganization.getNodeNameWithVersion(dataset.label_key),
    data: dataset.data,
  }))

  const sortedData = data.sort((a, b) => b.data[0] - a.data[0])

  return (
    <CumulativeBarChartTemplate
      title={t("score:score.13.chartGHGEmissionsDistribution.title")}
      datasets={sortedData}
      labelPath={"score:score.13.chartGHGEmissionsDistribution"}
      labelKeys={label_keys}
      unitPath={"units:units.emission"}
      unitKey={unit}
      helpText={t(`score:score.13.chartGHGEmissionsDistribution.help`)}
    />
  )
}

ChartODD13GHGEmissionsDistributionConsolidateMolecule.propTypes = {
  score: PropTypes.object,
}

export default ChartODD13GHGEmissionsDistributionConsolidateMolecule
