import React from "react"
import { Page } from "@shopify/polaris"
import Loading from "components/widgets/Loading"
import { Redirect, useParams } from "react-router-dom"
import AnalyzeGHGTemplate from "views/templates/AnalyzeGHGTemplate"
import { useTranslation } from "react-i18next"
import GHGAnalyzeOrganism from "views/organisms/analyzes/GHGAnalyzeOrganism"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import { useAppContext } from "components/AppContext"
import ObjectiveNavigationTemplate from "views/templates/ObjectiveNavigationTemplate"
import withAuthRequired from "../../components/auth/withAuthRequired"
import usePillars from "views/organisms/pillars/usePillars"

const AnalyzeGHGPage = () => {
  const { methodology, based } = useParams()
  const { t } = useTranslation()

  const {
    currentPeriod,
    parentOrganization,
    envScore,
    isEnvScoreLoading,
    isCurrentNodeLoading,
    currentRootNode,
    isCurrentRootNodeLoading,
  } = useAppContext()

  const tabs = [
    {
      name: t("analyze:tabs.GHGProtocolLocationBased"),
      methodology: "ghg_protocol",
      based: "location_based",
      selected: "ghg_protocol" === methodology && based === "location_based",
      url: "/analyze/ghg-scopes/environmental/13/ghg_protocol/location_based",
      order: currentRootNode?.methodology === "ghg_protocol" ? 2 : 3,
    },
    {
      name: t("analyze:tabs.GHGProtocolMarketBased"),
      methodology: "ghg_protocol",
      based: "market_based",
      selected: "ghg_protocol" === methodology && based === "market_based",
      url: "/analyze/ghg-scopes/environmental/13/ghg_protocol/market_based",
      order: currentRootNode?.methodology === "ghg_protocol" ? 1 : 4,
    },
    {
      name: t("analyze:tabs.BilanCarboneLocationBased"),
      methodology: "bilan_carbone",
      based: "location_based",
      selected: "bilan_carbone" === methodology && based === "location_based",
      url: "/analyze/ghg-scopes/environmental/13/bilan_carbone/location_based",
      order: currentRootNode?.methodology === "ghg_protocol" ? 4 : 1,
    },
    {
      name: t("analyze:tabs.BilanCarboneMarketBased"),
      methodology: "bilan_carbone",
      based: "market_based",
      selected: "bilan_carbone" === methodology && based === "market_based",
      url: "/analyze/ghg-scopes/environmental/13/bilan_carbone/market_based",
      order: currentRootNode?.methodology === "ghg_protocol" ? 3 : 2,
    },
  ]
    .filter(
      (tab) => !!envScore?.[`env_score_${tab.methodology}_${tab.based}`]?.show
    )
    .sort((a, b) => a.order - b.order)

  const objectives = [
    {
      objectiveId: 13,
      selected: true,
      url: tabs[0]?.url,
    },
  ]

  const pillars = usePillars({
    pageName: "/analyze/ghg-scopes",
    [PILLAR_SOCIAL]: { disabled: true },
    [PILLAR_PROFIT]: { disabled: true },
  })

  if (isEnvScoreLoading || isCurrentNodeLoading || isCurrentRootNodeLoading) {
    return <Loading />
  }

  if (!methodology) {
    return <Redirect to={tabs[0].url} />
  }

  return (
    <ObjectiveNavigationTemplate>
      <Page fullWidth={!!envScore}>
        <AnalyzeGHGTemplate
          envScore={envScore}
          parentOrganization={parentOrganization}
          tabs={tabs}
          themeType={PILLAR_ENVIRONMENTAL}
          objectives={objectives}
          pillars={pillars}
          periodName={currentPeriod?.name}
        >
          <GHGAnalyzeOrganism
            methodology={methodology}
            based={based}
            envScore={envScore}
            parentOrganization={parentOrganization}
          />
        </AnalyzeGHGTemplate>
      </Page>
    </ObjectiveNavigationTemplate>
  )
}

export default withAuthRequired(AnalyzeGHGPage)
