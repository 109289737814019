import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

const ResponsibleProductionTableHeader = ({ domain, equipmentTypes }) => {
  const { t } = useTranslation()

  const showSoldLifetimeColumn = equipmentTypes.some(
    (equipmentType) => equipmentType.any_sold_equipment
  )

  return (
    <thead>
      <tr className="title">
        <th rowSpan="2"></th>
        <th className="separated">
          {t(
            "collect:questions.12.sustainable_production.table_1.column_header_1"
          )}
        </th>
        <th colSpan="4">
          {t(
            "collect:questions.12.sustainable_production.table_1.column_header_2"
          )}
        </th>
      </tr>
      <tr>
        <th className="separated">
          {t("collect:questions.12.sustainable_production.table_1.column_1")}
        </th>
        <th>
          {domain === "workplace" &&
            t("collect:questions.12.sustainable_production.table_1.column_2")}
        </th>
        <th>
          {t("collect:questions.12.sustainable_production.table_1.column_3")}
        </th>
        <th>
          {showSoldLifetimeColumn &&
            t(
              "collect:questions.12.sustainable_production.table_1.sold_lifetime"
            )}
        </th>
        <th>
          {t("collect:questions.12.sustainable_production.table_1.column_4")}
        </th>
      </tr>
    </thead>
  )
}

export default ResponsibleProductionTableHeader

ResponsibleProductionTableHeader.propTypes = {
  domain: PropTypes.string.isRequired,
  equipmentTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
}
