export const PILLAR_ENVIRONMENTAL = "environmental"
export const PILLAR_SOCIAL = "social"
export const PILLAR_PROFIT = "financial"
export const PILLARS = [PILLAR_ENVIRONMENTAL, PILLAR_SOCIAL, PILLAR_PROFIT]

export const PILLARS_OBJECTIVES = {
  [PILLAR_ENVIRONMENTAL]: [13, 12, 15, 7, 6],
  [PILLAR_SOCIAL]: [3, 4, 5, 10, 1, 2, 11],
  [PILLAR_PROFIT]: [8, 9, 16, 17],
}

export const sdg_theme_1_2_11 = ["societal_commitments", "positive_impact"]

export const OBJECTIVES_THEMES = {
  "1_2_11": sdg_theme_1_2_11,
  1: sdg_theme_1_2_11,
  2: sdg_theme_1_2_11,
  3: ["well_being", "positive_impact"],
  4: ["education", "positive_impact"],
  5: ["parity", "positive_impact"],
  6: ["water_consumption", "positive_impact"],
  7: ["energy_consumption", "positive_impact"],
  8: [
    "investments",
    "project_portfolio",
    "project_governance",
    "eco_design",
    "positive_impact",
  ],
  9: [
    "innovation_portfolio",
    "governance",
    "internal_innovation",
    "positive_impact",
  ],
  10: ["staff", "recruitment", "inclusion", "diversity", "positive_impact"],
  11: sdg_theme_1_2_11,
  12: ["sustainable_consumption", "sustainable_production", "positive_impact"],
  13: [
    "workplace",
    "datacenter_internal",
    "datacenter_external",
    "network",
    "software",
    "it_team",
    "cloud",
    "business_assets",
    "positive_impact",
  ],
  14: [],
  15: ["printing", "electronic_waste", "positive_impact"],
  16: [
    "csr_strategy",
    "cdr_strategy",
    "ethics",
    "cyber_security",
    "artificial_intelligence",
    "positive_impact",
  ],
  17: ["partnerships_sourcing", "partnerships", "positive_impact"],
}
