import PropTypes from "prop-types"
import React from "react"
import ITDomainAnalysisLayoutOrganism from "views/organisms/canvases/charts/ITDomainAnalysisLayoutOrganism"
import ChartODD13ScopePerSubScopeBreakdownMolecule from "views/molecules/canvases/charts/GHGProtocol/ChartODD13ScopePerSubScopeBreakdownMolecule"
import TextChartODD13ScopeMolecule from "views/molecules/canvases/charts/GHGProtocol/TextChartODD13ScopeMolecule"
import { useTranslation } from "react-i18next"
import TextChartODD13GHGEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsMolecule"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import ChartODD13SplitBetweenScopeMolecule from "views/molecules/canvases/charts/GHGProtocol/ChartODD13SplitBetweenScopeMolecule"

const GHGScopesOrganism = ({ score, methodology, based }) => {
  const { t } = useTranslation()

  return (
    <ITDomainAnalysisLayoutOrganism
      maturity={score?.odd13_maturity ?? 0}
      chart1={
        <TextChartODD13GHGEmissionsMolecule
          score={score}
          ghgEmissions={
            score[`env_score_${methodology}_${based}`].kpi_co2_total
          }
          hidePercentage
        />
      }
      chart2={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:ghg_scopes.textChartODD13ScopeMolecule.title.scope2Help`
          )}
        >
          <TextChartODD13ScopeMolecule
            score={score}
            scope1="scope2"
            methodology={methodology}
            based={based}
          />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart3={
        <ChartODD13ScopePerSubScopeBreakdownMolecule
          score={score}
          methodology={methodology}
          based={based}
          scope="scope2"
          helpText={t(
            `analyze:ghg_scopes.chartODD13ScopePerSubScopeBreakdownMolecule.title.scope2Help`
          )}
        />
      }
      chart4={
        <ChartODD13SplitBetweenScopeMolecule
          score={score}
          methodology={methodology}
          scope="scope1"
          helpText={t(
            `analyze:ghg_scopes.chartODD13SplitBetweenScopeMolecule.help`
          )}
          based={based}
        />
      }
      chart5={
        <HelpWidgetChartInteractiveMolecule
          text={t(
            `analyze:ghg_scopes.textChartODD13ScopeMolecule.title.scope3Help`
          )}
        >
          <TextChartODD13ScopeMolecule
            score={score}
            scope1="scope3"
            methodology={methodology}
            based={based}
          />
        </HelpWidgetChartInteractiveMolecule>
      }
      chart6={
        <ChartODD13ScopePerSubScopeBreakdownMolecule
          score={score}
          methodology={methodology}
          scope="scope3"
          helpText={t(
            `analyze:ghg_scopes.chartODD13ScopePerSubScopeBreakdownMolecule.title.scope3Help`
          )}
          based={based}
        />
      }
    />
  )
}

GHGScopesOrganism.propTypes = {
  score: PropTypes.object.isRequired,
  methodology: PropTypes.oneOf(["ghg_protocol", "bilan_carbone"]).isRequired,
  based: PropTypes.oneOf(["market_based", "location_based"]).isRequired,
}

export default GHGScopesOrganism
