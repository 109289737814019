import React from "react"
import {
  generateCollectedEquipmentName,
  reusablePersonalWorkplaceEquipmentTypes,
} from "helpers/CollectHelper"
import {
  CheckboxField,
  TextField,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import PropTypes from "prop-types"
import { WORKPLACE } from "utils/constants/equipmentDomains"
import _ from "lodash"
import { useFormContext } from "components/widgets/CollectPage/forms/FormContext"
import { useTranslation } from "react-i18next"
import { useAppContext } from "components/AppContext"

const ResponsibleConsumptionTableBodyLines = ({ equipmentTypes, domain }) => {
  const { t } = useTranslation()
  const { formData } = useFormContext()
  const { envCollect } = useAppContext()
  const isReusableWorkplaceEquipment = (equipmentType) =>
    domain === WORKPLACE &&
    reusablePersonalWorkplaceEquipmentTypes.some(
      (equipment) => equipment === equipmentType
    )

  let getUpstreamLifetimeArray = []
  const hiddenUpstreamLifetimeColumn = equipmentTypes.every((equipmentType) => {
    const collectedSecondHandRatio = _.get(
      formData,
      `collected_equipments.[${domain}].[${equipmentType.name}].second_hand_ratio`,
      null
    )
    getUpstreamLifetimeArray.push(collectedSecondHandRatio)
    return (
      +collectedSecondHandRatio === 0 ||
      collectedSecondHandRatio === null ||
      collectedSecondHandRatio === undefined
    )
  })

  return equipmentTypes.map((equipmentType, index) => {
    const collectedSecondHandRatio = _.get(
      formData,
      `collected_equipments.[${domain}].[${equipmentType.name}].second_hand_ratio`,
      null
    )
    const hiddenUpstreamLifetime =
      +collectedSecondHandRatio === 0 ||
      collectedSecondHandRatio === null ||
      collectedSecondHandRatio === undefined
    getUpstreamLifetimeArray.push(collectedSecondHandRatio)

    const collectedEquipment = envCollect?.collected_equipments?.find(
      (equipment) =>
        equipment.domain === domain &&
        equipment.equipment_type === equipmentType.name
    )

    return (
      <tr key={index}>
        <td>{generateCollectedEquipmentName(equipmentType.name)}</td>
        <td className="top">
          <TextField
            id={`collected_equipments.[${domain}].[${equipmentType.name}].second_hand_ratio`}
            type="number"
          />
        </td>
        <td className="top" hidden={hiddenUpstreamLifetimeColumn}>
          {!hiddenUpstreamLifetime && (
            <TextField
              id={`collected_equipments.[${domain}].[${equipmentType.name}].upstream_lifetime`}
              type="number"
              hidden={hiddenUpstreamLifetime}
            />
          )}
        </td>
        <td className="top">
          {isReusableWorkplaceEquipment(equipmentType.name) && (
            <TextField
              id={`collected_equipments.[${domain}].[${equipmentType.name}].internal_reuse_ratio`}
              type="number"
            />
          )}
        </td>
        <td className="top">
          {!equipmentType.all_purchasing_year_present && (
            <>
              <TextField
                id={`collected_equipments.[${domain}].[${equipmentType.name}].purchasing_year`}
                type="number"
              />
              {collectedEquipment?.show_is_purchasing_year_include_in_period_checkbox && (
                <CheckboxField
                  overrideLabel={t(
                    "collect:questions.12.sustainable_consumption.collected_equipments.is_purchasing_year_include_in_period.label"
                  )}
                  id={`collected_equipments.[${domain}].[${equipmentType.name}].is_purchasing_year_include_in_period`}
                />
              )}
            </>
          )}
        </td>
      </tr>
    )
  })
}

export default ResponsibleConsumptionTableBodyLines

ResponsibleConsumptionTableBodyLines.propTypes = {
  equipmentTypes: PropTypes.array.isRequired,
  domain: PropTypes.string.isRequired,
}
