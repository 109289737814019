import React from "react"
import { ExternalLabel } from "components/widgets/CollectPage/forms/fields/CollectFields"
import ResponsibleProductionTableHeader from "views/molecules/collects/objectives/12/responsibleProduction/ResponsibleProductionTableHeader"
import PropTypes from "prop-types"
import TitleFormCollect from "views/atoms/forms/collects/title/TitleFormCollect"
import { useTranslation } from "react-i18next"
import "stylesheets/blocs/molecules/table/ResponsibleProduction.scss"
import ResponsibleProductionTableBodyLines from "./ResponsibleProductionTableBodyLines"
import { DOMAIN_TO_MODULE } from "utils/constants/collects/modules"
import { Module } from "components/widgets/CollectPage/forms/Module"

const ResponsibleProductionTable = ({ equipmentTypes }) => {
  const { t } = useTranslation()

  return (
    <div>
      <ExternalLabel translationPathName="table_1" />
      {equipmentTypes.map((domain, index) => (
        <Module
          module={DOMAIN_TO_MODULE[domain.name]}
          key={DOMAIN_TO_MODULE[domain.name]}
        >
          <div key={index}>
            <TitleFormCollect
              text={t(
                `collect:questions.12.sustainable_consumption.table_1.${domain.name}.title`
              )}
            />
            <table className="table__responsible-production">
              <ResponsibleProductionTableHeader
                domain={domain.name}
                equipmentTypes={domain.equipment_types}
              />
              <tbody>
                <ResponsibleProductionTableBodyLines
                  equipmentTypes={domain.equipment_types}
                  domain={domain.name}
                />
              </tbody>
            </table>
          </div>
        </Module>
      ))}
    </div>
  )
}

export default ResponsibleProductionTable

ResponsibleProductionTable.propTypes = {
  equipmentTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
}
