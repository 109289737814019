import React, { useEffect, useReducer, useState } from "react"
import {
  Form,
  FormLayout,
  Modal,
  Select,
  TextField,
  TextStyle,
} from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { useEntity } from "hooks/useAxios"
import EnergyConsumptionInventoryEntity from "services/entities/EnergyConsumptionInventoryEntity"
import { useAppContext } from "components/AppContext"
import CountryApp from "services/apps/countryApp"
import CentralizationInventoryEntity from "services/entities/CentralizationInventoryEntity"
import { useCollectContext } from "components/widgets/CollectPage/CollectContextProvider"

const translationPrefix =
  "collect:questions.7.energy_consumption.energy_consumption_inventories.fields."

const ENERGY_TYPES = [
  "nuclear",
  "unknown_fuel_combustion",
  "coal",
  "oil",
  "gas",
  "unknown_renewable",
  "solar",
  "geothermic",
  "wind",
  "hydraulic",
  "biomass",
]

const CATEGORIES = ["office", "factory", "internal_hosting"]

const NOT_INTERNAL_HOSTING_CATEGORIES = ["office", "factory"]

const isNotInternalHosting = (category) =>
  !!category && NOT_INTERNAL_HOSTING_CATEGORIES.includes(category)

const isInternalHosting = (category) =>
  !!category && !isNotInternalHosting(category)

const formReducer = (state, action) => {
  if (action === null) {
    return {}
  }

  return { ...state, ...action }
}

const EnergyConsumptionModal = ({
  open,
  setOpen,
  disabled,
  onSaved,
  updateFormData,
  dataCenterInternalInventoryId,
}) => {
  const { t } = useTranslation()
  const [formData, dispatch] = useReducer(formReducer, updateFormData || {})
  const [errors, setErrors] = useState({})
  const [dataCenters, setDataCenters] = useState([])
  const { countryOptions, reloadEnvCollect } = useAppContext()
  const { collect } = useCollectContext()

  const energyConsumptionInventoryEntity = useEntity(
    EnergyConsumptionInventoryEntity
  )
  const centralizationInventoryEntity = useEntity(CentralizationInventoryEntity)

  useEffect(async () => {
    const response = await centralizationInventoryEntity.getDatacenterInternal()
    setDataCenters(response.data)
  }, [])

  useEffect(() => {
    dispatch(updateFormData)
  }, [updateFormData])

  useEffect(() => {
    if (dataCenterInternalInventoryId) {
      dispatch({
        data_center_internal_inventory_id: dataCenterInternalInventoryId,
        category: "internal_hosting",
      })
    }
  }, [dataCenterInternalInventoryId])

  const createEnergyConsumptionInventory = async () => {
    try {
      setErrors({})
      formData.id
        ? await energyConsumptionInventoryEntity.update(formData.id, formData)
        : await energyConsumptionInventoryEntity.create(formData)

      reloadEnvCollect()
      setOpen(false)
      onSaved()
      dispatch(null)
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  const deleteEnergyConsumptionInventory = async () => {
    try {
      setErrors({})
      await energyConsumptionInventoryEntity.delete(formData.id)

      reloadEnvCollect()
      setOpen(false)
      onSaved()
      dispatch(null)
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  const energyTypeOptions = [
    { label: "", value: null },
    ...ENERGY_TYPES.map((type) => ({
      label: t(
        "collect:questions.7.energy_consumption.energy_consumption_inventories.energy_types." +
          type
      ),
      value: type,
    })),
  ]

  const countrySelectOptions = [
    { label: "", value: null },
    ...countryOptions.map((countryCode) => {
      return {
        label: CountryApp.getNameI18n(countryCode),
        value: countryCode,
      }
    }),
  ]

  const datacenterSelectOptions = [
    { label: "", value: null },
    ...dataCenters.map((dataCenter) => {
      return {
        label: dataCenter.name,
        value: dataCenter.id,
      }
    }),
  ]

  const categoryOptions = [
    { label: "", value: null },
    ...CATEGORIES.map((category) => ({
      label: t(
        "collect:questions.7.energy_consumption.energy_consumption_inventories.category." +
          category
      ),
      value: category,
    })),
  ]

  const title = formData.id
    ? t(
        "collect:questions.7.energy_consumption.energy_consumption_inventories.modal.update_label"
      )
    : t(
        "collect:questions.7.energy_consumption.energy_consumption_inventories.modal.create_label"
      )

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title={title}
      primaryAction={{
        content: t("common:save"),
        onAction: createEnergyConsumptionInventory,
        loading: false,
        disabled,
      }}
      secondaryActions={
        formData.id && collect?.closed_at === null
          ? {
              content: t("common:delete"),
              onAction: deleteEnergyConsumptionInventory,
              destructive: true,
              loading: false,
            }
          : null
      }
    >
      <Modal.Section>
        <div className="environmental">
          <Form
            implicitSubmit={false}
            onSubmit={createEnergyConsumptionInventory}
          >
            <FormLayout>
              {!dataCenterInternalInventoryId && (
                <Select
                  id="category"
                  label={t(translationPrefix + "category")}
                  value={formData.category}
                  onChange={(value) => dispatch({ category: value })}
                  error={errors.category}
                  disabled={disabled}
                  options={categoryOptions}
                />
              )}
              {formData.category && (
                <FormLayout>
                  {isNotInternalHosting(formData.category) && (
                    <FormLayout.Group condensed>
                      <TextField
                        id="workspace"
                        label={t(translationPrefix + "workspace")}
                        value={formData.workspace}
                        onChange={(value) => dispatch({ workspace: value })}
                        error={errors.workspace}
                        disabled={disabled}
                      />
                    </FormLayout.Group>
                  )}
                  {isInternalHosting(formData.category) &&
                    !dataCenterInternalInventoryId && (
                      <FormLayout.Group condensed>
                        <Select
                          id="data_center_internal_inventory_id"
                          label={t(
                            translationPrefix +
                              "data_center_internal_inventory_id"
                          )}
                          value={formData.data_center_internal_inventory_id}
                          onChange={(value) =>
                            dispatch({
                              data_center_internal_inventory_id:
                                value === "" ? null : +value,
                            })
                          }
                          error={errors.data_center_internal_inventory_id}
                          disabled={disabled}
                          options={datacenterSelectOptions}
                        />
                      </FormLayout.Group>
                    )}
                  <FormLayout.Group condensed>
                    <TextField
                      id="provider"
                      label={t(translationPrefix + "provider")}
                      value={formData.provider}
                      onChange={(value) => dispatch({ provider: value })}
                      error={errors.provider}
                      disabled={disabled}
                      autofocus
                      focused
                    />
                    <Select
                      id="provider_country"
                      label={t(translationPrefix + "provider_country")}
                      value={formData.provider_country}
                      onChange={(value) =>
                        dispatch({ provider_country: value })
                      }
                      error={errors.provider_country}
                      disabled={disabled}
                      options={countrySelectOptions}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group condensed>
                    <TextField
                      id="provider_emission_factor"
                      type="number"
                      label={t(translationPrefix + "provider_emission_factor")}
                      value={formData.provider_emission_factor}
                      onChange={(value) =>
                        dispatch({ provider_emission_factor: value })
                      }
                      error={errors.provider_emission_factor}
                      disabled={disabled}
                      suffix="gCO2e/kWh"
                    />
                  </FormLayout.Group>
                  <FormLayout.Group condensed>
                    <TextField
                      id="energy_consumption"
                      type="number"
                      label={t(translationPrefix + "energy_consumption")}
                      value={formData.energy_consumption}
                      onChange={(value) =>
                        dispatch({ energy_consumption: value })
                      }
                      error={errors.energy_consumption}
                      disabled={disabled}
                      suffix="kWh"
                    />
                    <Select
                      id="energy_type"
                      label={t(translationPrefix + "energy_type")}
                      value={formData.energy_type}
                      onChange={(value) => dispatch({ energy_type: value })}
                      error={errors.energy_type}
                      disabled={disabled}
                      options={energyTypeOptions}
                    />
                  </FormLayout.Group>
                  {isNotInternalHosting(formData.category) && (
                    <FormLayout.Group condensed>
                      <TextField
                        id="it_staff_in_building_ratio"
                        type="number"
                        label={t(
                          translationPrefix + "it_staff_in_building_ratio"
                        )}
                        value={`${formData.it_staff_in_building_ratio}`}
                        onChange={(value) =>
                          dispatch({ it_staff_in_building_ratio: value })
                        }
                        error={errors.it_staff_in_building_ratio}
                        disabled={disabled}
                        suffix="%"
                      />
                    </FormLayout.Group>
                  )}
                </FormLayout>
              )}
            </FormLayout>
          </Form>
        </div>
        <br />
        <TextStyle variation="subdued">
          {t("collect:questions.common.nota_bene")}
        </TextStyle>
      </Modal.Section>
    </Modal>
  )
}

EnergyConsumptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSaved: PropTypes.func.isRequired,
  updateFormData: PropTypes.object,
  dataCenterInternalInventoryId: PropTypes.number,
}

EnergyConsumptionModal.defaultProps = {
  disabled: false,
  updateFormData: null,
  dataCenterInternalInventoryId: null,
}

export default EnergyConsumptionModal
