import PropTypes from "prop-types"
import React from "react"
import TextChartODD13GHGEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsMolecule"
import TextChartODD13EnergyMixMolecule from "views/molecules/canvases/charts/TextChartODD13EnergyMixMolecule"
import { NODE_TYPE_SITE } from "utils/constants/organization/nodeTypes"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import { useAppContext } from "components/AppContext"
import { useTranslation } from "react-i18next"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"
import BrokerManufBreakdown from "views/molecules/charts/brocker/BrokerManufBreakdown"
import TextChartODD13GHGEmissionsAverageMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsAverageMolecule"

const Objective13LeaserAndSoldScoreLayoutOrganism = ({ score }) => {
  const { t } = useTranslation()
  const { currentRootNode } = useAppContext()

  const energyMixKpi = {
    country:
      currentRootNode?.methodology === "bilan_carbone"
        ? score?.node?.type === NODE_TYPE_SITE
          ? score?.node?.parent?.name
          : score?.node?.name
        : t(`score:score.13.chartEnergyMix.marketBased`),
    based:
      currentRootNode?.methodology === "bilan_carbone"
        ? t(`score:score.13.chartEnergyMix.locationBased`)
        : undefined,
  }

  const splitPerEquipmentTypeChart = () => {
    return (
      <DonutChartTemplate
        title={t(
          "score:score.13.workplace_broker.split_per_equipment_type_chart.title"
        )}
        data={score?.split_per_equipment_type_chart?.data}
        labelKeys={score?.split_per_equipment_type_chart?.labels}
        labelPath="common:equipment_types"
        unitPath="units:units.emission"
        unitKey="t_unit"
        helpText={t(
          "score:score.13.chartGHGEmissionsPerEmployee.corporate.help"
        )}
      />
    )
  }

  return (
    <ScoreLayoutOrganism
      maturity={score.odd13_maturity ?? 0}
      chart1={
        <TextChartODD13GHGEmissionsMolecule
          score={score}
          ghgEmissions={score?.odd13_CO2}
        />
      }
      chart2={
        <TextChartODD13EnergyMixMolecule
          energyMixStatus={score?.odd13_energy_mix_status}
          energyMixValue={
            currentRootNode?.methodology === "bilan_carbone"
              ? Number(score?.odd13_energy_mix_value)
              : Number(score?.odd7_market_based_emission_factor)
          }
          country={energyMixKpi.country}
          based={energyMixKpi.based}
        />
      }
      chart3={
        <BrokerManufBreakdown
          title={t(
            "score:score.13.workplace_broker.ghg_emissions_per_brand_leased.title"
          )}
          score={score?.broker}
          subKey="ghg_emissions_per_brand_leaser_chart"
        />
      }
      chart4={splitPerEquipmentTypeChart()}
      chart5={<TextChartODD13GHGEmissionsAverageMolecule score={score} />}
      chart6={
        <BrokerManufBreakdown
          title={t(
            "score:score.13.workplace_broker.ghg_emissions_per_brand_sold.title"
          )}
          score={score?.broker}
          subKey="ghg_emissions_per_brand_sold_chart"
        />
      }
    />
  )
}

Objective13LeaserAndSoldScoreLayoutOrganism.propTypes = {
  score: PropTypes.object.isRequired,
}

Objective13LeaserAndSoldScoreLayoutOrganism.defaultProps = {}

export default Objective13LeaserAndSoldScoreLayoutOrganism
