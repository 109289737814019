import React, { useCallback } from "react"
import { FormLayout } from "@shopify/polaris"
import {
  CENTRALIZATION_FIELD,
  FORM_MODE_CREATE,
  HIDDEN_CREATE_FIELDS,
  HIDDEN_FIELDS,
  HIDDEN_FIELDS_FOR_NO_PROVIDER,
  PARENT_FIELD,
} from "utils/constants/forms/nodeForms"
import { useNodeContext } from "views/organisms/interactive/NodeContext"
import { useAppContext } from "components/AppContext"
import { useTranslation } from "react-i18next"
import { isPropertyFrozen } from "services/organizations/NodeFieldsFrozen"

const FIELD_DISABLED_BY_FROZEN_PROFIT = [
  "name",
  "parent",
  "employees",
  "digital_employees",
  "budget_formatted",
  "budget_unit",
]

const FIELD_DISABLED_BY_FROZEN_ENV = ["name", "parent"]

const FIELD_DISABLED_BY_FROZEN_INVENTORIES = ["name", "parent"]
const NodeFormFields = ({
  mode,
  disabled,
  formFieldsToComponents,
  nodeTypeToFormFields,
}) => {
  const PROPERTIES_TO_ROOT = [PARENT_FIELD, CENTRALIZATION_FIELD]
  const { t } = useTranslation()
  const { currentRootNode, currentOrganization } = useAppContext()
  const { node, setNode, errors } = useNodeContext()

  const isProfitFrozen =
    node.centralized_policies_properties_frozen &&
    node.centralized_policies_properties_frozen.is_profit_frozen
  const isEnvFrozen =
    node.centralized_policies_properties_frozen &&
    node.centralized_policies_properties_frozen.is_env_frozen

  const propertyMapper = useCallback(
    (property) =>
      property === PARENT_FIELD ? node.parent : node.characteristic?.[property],
    [node]
  )

  const updateConsolidationModeIfSectorChanged = useCallback(
    (property, value) => {
      if (property === "sector" && !node.env_governance_frozen) {
        if (value === "leaser") {
          node.characteristic.consolidation_mode = "equity_share"
        } else {
          node.characteristic.consolidation_mode = "operational_control"
        }
      }
    },
    [node]
  )

  const onChange = useCallback(
    (property, value) => {
      if (!PROPERTIES_TO_ROOT.includes(property)) {
        node.characteristic[property] = value
      } else {
        node[property] = value
      }
      updateConsolidationModeIfSectorChanged(property, value)

      setNode({ ...node })
    },
    [node]
  )

  const generateFields = useCallback(() => {
    let type = node.type

    if (!type) {
      return null
    }

    let fields
    if (mode === FORM_MODE_CREATE) {
      fields = nodeTypeToFormFields[type].filter(
        (property) => !HIDDEN_CREATE_FIELDS.includes(property)
      )
    } else {
      fields = nodeTypeToFormFields[type].filter(
        (property) => !HIDDEN_FIELDS.includes(property)
      )
    }

    if (!currentRootNode.is_provider) {
      fields = nodeTypeToFormFields[type].filter(
        (property) => !HIDDEN_FIELDS_FOR_NO_PROVIDER.includes(property)
      )
    }

    if (!fields) return null

    return fields.map((property) => {
      if (Array.isArray(property)) {
        return (
          <FormLayout.Group key={property}>
            {property.map((subProperty) => {
              const Component = formFieldsToComponents[subProperty]
              return (
                <Component
                  key={subProperty}
                  nodeId={node.id}
                  type={type}
                  property={subProperty}
                  value={propertyMapper(subProperty)}
                  error={errors?.[subProperty]}
                  onChange={(value) => onChange(subProperty, value)}
                  disabled={
                    disabled ||
                    isPropertyFrozen(
                      subProperty,
                      isEnvFrozen,
                      FIELD_DISABLED_BY_FROZEN_ENV
                    ) ||
                    isPropertyFrozen(
                      subProperty,
                      isProfitFrozen,
                      FIELD_DISABLED_BY_FROZEN_PROFIT
                    ) ||
                    isPropertyFrozen(
                      subProperty,
                      node.centralized_inventories_properties_frozen,
                      FIELD_DISABLED_BY_FROZEN_INVENTORIES
                    )
                  }
                  mode={mode}
                />
              )
            })}
          </FormLayout.Group>
        )
      } else {
        const Component = formFieldsToComponents[property]
        const label =
          currentOrganization?.is_partial_partner &&
          type === "country_team" &&
          property === "team_member_count"
            ? t(`activation:${type}.questions.${property}.label_guest`)
            : null
        property = property === "country_name" ? "name" : property
        return (
          <FormLayout.Group key={property}>
            <Component
              nodeId={node.id}
              type={type}
              property={property}
              value={propertyMapper(property)}
              error={errors?.[property]}
              onChange={(value) => onChange(property, value)}
              disabled={
                disabled ||
                isPropertyFrozen(
                  property,
                  isEnvFrozen,
                  FIELD_DISABLED_BY_FROZEN_ENV
                ) ||
                isPropertyFrozen(
                  property,
                  isProfitFrozen,
                  FIELD_DISABLED_BY_FROZEN_PROFIT
                ) ||
                isPropertyFrozen(
                  property,
                  node.centralized_inventories_properties_frozen,
                  FIELD_DISABLED_BY_FROZEN_INVENTORIES
                )
              }
              mode={mode}
              label={label}
            />
          </FormLayout.Group>
        )
      }
    })
  }, [node, errors])

  return generateFields()
}

export default NodeFormFields
