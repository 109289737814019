import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import Config from "assets/configs/score.js"
import { RETURN_OBJECTS } from "services/translations/config"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"

const ChartODD13GHGEmissionOverviewMolecule = ({ score }) => {
  const { t } = useTranslation()

  const { title, help } = t(
    `score:score.13.chartODD13GHGEmissionOverview`,
    RETURN_OBJECTS
  )

  const labels = t(
    "score:score.13.chartGHGEmissionsDistribution",
    RETURN_OBJECTS
  )

  const generateData = useCallback(() => {
    const { data, label_keys, unit } =
      score?.odd13_emissions_breakdown_consolidated_parent || {}

    return data.map((value, index) => {
      return {
        label: labels[label_keys[index]],
        data: value,
        backgroundColor: Config.common.colors[index % data.length],
        unit,
      }
    })
  }, [score])

  const data = generateData().map((dataset) => dataset.data)
  const label = generateData().map((dataset) => dataset.label)
  const unit = generateData().map((dataset) => dataset.unit)

  return (
    <DonutChartTemplate
      title={title}
      data={data}
      labelKeys={label}
      unitPath={"units:units.emission"}
      unitKey={unit}
      ifUnitsIsArray
      helpText={help}
    />
  )
}

ChartODD13GHGEmissionOverviewMolecule.propTypes = {
  score: PropTypes.object.isRequired,
}

export default ChartODD13GHGEmissionOverviewMolecule
