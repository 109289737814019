import Config from "assets/configs/score.js"
import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { COLORS } from "utils/constants/colors"
import BarChartTemplate from "views/molecules/charts/template/BarChartTemplate"

const CloudProviderChart = ({ clouds }) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.7."

  const collectPresentDCs = new Set(clouds)

  const config = Config["7"]["chart3"]
  const data = config.data_order.map((cloudProvider, index) => ({
    label: cloudProvider,
    data: config.data_match[cloudProvider],
    color: collectPresentDCs.has(cloudProvider.toLowerCase())
      ? COLORS.planet.primary
      : config.colors_cloud[index % config.colors_cloud.length],
  }))
  const getData = data.map((dataset) => dataset.data)
  const getLabels = data.map((dataset) => dataset.label)
  const getColors = data.map((dataset) => dataset.color)

  return (
    <BarChartTemplate
      title={t(translationPrefix + "chart3.title")}
      data={getData}
      dataBarTitle={t(translationPrefix + "chart3.data_title")}
      labelKeys={getLabels}
      unitPath="units:units"
      unitKey="percentage"
      modifiedBarColors={getColors}
      showLegend
      helpText={t(translationPrefix + "chart3.help")}
    />
  )
}

CloudProviderChart.propTypes = {
  clouds: PropTypes.array.isRequired,
}

export default CloudProviderChart