const EnergyConsumptionInventoryEntity = (client) => ({
  index: (params) =>
    client.get("/api/energy_consumption_inventories", { params }),
  create: (data) => client.post("/api/energy_consumption_inventories", data),
  update: (id, data) =>
    client.put(`/api/energy_consumption_inventories/${id}`, data),
  delete: (id) => client.delete(`/api/energy_consumption_inventories/${id}`),
})

export default EnergyConsumptionInventoryEntity
