import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import RadarChartTemplate from "views/molecules/charts/template/RadarChartTemplate"

const ChartSdg13MaturityScoresMolecule = ({ maturityScore }) => {
  const { t } = useTranslation()

  const labels = maturityScore.labels.map((label) => t(`score:score.13.chartGHGWebsitesEmissions.modal.chartMaturityScores.legend.${label}`))

  const data = maturityScore.datasets.map((dataset) => ({
    label: dataset.label,
    data: dataset.data.datas,
  }))

  return (
    <RadarChartTemplate
      title={t("score:score.13.chartGHGWebsitesEmissions.modal.chartMaturityScores.title")}
      datasets={data}
      labelKeys={labels}
      chartFill
      gapSize={10}
      helpText={t(`score:score.13.chartGHGWebsitesEmissions.modal.chartMaturityScores.help`)}
    />
  )
}

ChartSdg13MaturityScoresMolecule.propTypes = {
  maturityScore: PropTypes.object.isRequired,
}

export default ChartSdg13MaturityScoresMolecule
