import React from "react"
import { NODE_TYPE_SITE } from "utils/constants/organization/nodeTypes"
import { useAppContext } from "components/AppContext"
import { useTranslation } from "react-i18next"

export const EnergyMixKpi = (score) => {
  const { t } = useTranslation()
  const { currentRootNode } = useAppContext()

  let country
  let based

  if (currentRootNode?.methodology === "bilan_carbone") {
    if (score?.node?.type === NODE_TYPE_SITE) {
      country = score?.node?.parent?.name
      if (score?.node?.parent?.name === undefined) {
        based = t(`score:score.13.chartEnergyMix.locationBased`)
      }
    } else {
      country = score?.node?.name
      if (score?.node?.name === undefined) {
        based = t(`score:score.13.chartEnergyMix.locationBased`)
      }
    }
  } else {
    country = t(`score:score.13.chartEnergyMix.marketBased`)
    based = undefined
  }

  return {
    country,
    based,
  }
}
