import SCORE_CONFIG from "assets/configs/score"
import {
  getCompareBarColors,
  getFabAndUseCompareBarColors,
} from "services/charts/ColorBar"
import { FilterTooLittleValuesInDatasets } from "services/charts/ConvertData"

export const WHITE_BORDER_DATASET = {
  borderWidth: 1,
  borderColor: "#fff",
}

const COLORS = SCORE_CONFIG.common.colors
const AVGCOLORS = (data, reference) => {
  return data.map((value, index) =>
    value === reference[index]
      ? SCORE_CONFIG.common.colorsEvaluation.yellow
      : value > reference[index]
      ? SCORE_CONFIG.common.colorsEvaluation.green
      : SCORE_CONFIG.common.colorsEvaluation.red
  )
}

export const getFormattedDatasets = ({
  datasets,
  labelKeys,
  addOtherCategory,
  colorsBarComparative,
  referenceData,
  compareBarColors,
  fabAndUseCompareBarColors,
  firstColumnAlwaysGrey,
  useBackgroundColorsInDatasets,
  greyIfOnly1Column,
} = {}) => {
  const ConvertedDatasets = addOtherCategory
    ? FilterTooLittleValuesInDatasets(datasets, labelKeys)
    : datasets
  const filteredDatasets = ConvertedDatasets.filter((dataset) => {
    return dataset.data.some((value) => value !== undefined)
  })

  return filteredDatasets.map((dataset, index) => {
    let backgroundColor

    switch (true) {
      case colorsBarComparative:
        backgroundColor = AVGCOLORS(dataset.data, referenceData)
        break

      case compareBarColors:
        backgroundColor = getCompareBarColors(
          dataset.data,
          firstColumnAlwaysGrey,
          greyIfOnly1Column
        )
        break

      case fabAndUseCompareBarColors:
        backgroundColor = getFabAndUseCompareBarColors(filteredDatasets, index)
        break

      case useBackgroundColorsInDatasets:
        backgroundColor = dataset.backgroundColor
        break

      default:
        backgroundColor = COLORS[index % COLORS.length]
        break
    }

    return {
      ...dataset,
      order: 10,
      data: dataset.data,
      backgroundColor,
    }
  })
}
