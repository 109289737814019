export const stackedAxes = (string, max) => {
  return {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
        display: true,
        scaleLabel: {
          display: true,
          labelString: string,
        },
        ticks: {
          beginAtZero: true,
          max: max || undefined,
        },
      },
    ],
  }
}

export const MixedChartAxes = (unit, axisId, position) => {
  return {
    display: true,
    position: position,
    id: axisId,
    ticks: {
      beginAtZero: true,
    },
    scaleLabel: {
      display: !unit.includes("null"),
      labelString: unit,
    },
  }
}
