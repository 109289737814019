import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import CumulativeBarChartTemplate from "views/molecules/charts/template/CumulativeBarChartTemplate"

const ChartOdd7EnergyConsumptionPerProvider = ({ score }) => {
  const { t } = useTranslation()
  const scorePrefix = score?.odd7_energy_consumption_per_provider
  const translatePrefix = "score:score.7.chart6.energy_consumption_per_provider"

  const datasets = scorePrefix.datasets.map((dataset) => {
    return {
      ...dataset,
      label:
        dataset.label === "unknown"
          ? t(
              "collect:questions.7.energy_consumption.energy_consumption_inventories.energy_types.unknown"
            )
          : dataset.label,
    }
  })

  return (
    <CumulativeBarChartTemplate
      title={t(`${translatePrefix}.title`)}
      datasets={datasets}
      labelPath="collect:questions.7.energy_consumption.energy_consumption_inventories.energy_types"
      labelKeys={scorePrefix.label_keys}
      unitPath="units:units.energy"
      unitKey={scorePrefix.unit}
      helpText={t(`${translatePrefix}.help`)}
      doNotConvert
    />
  )
}

ChartOdd7EnergyConsumptionPerProvider.propTypes = {
  score: PropTypes.object.isRequired,
}

export default ChartOdd7EnergyConsumptionPerProvider
