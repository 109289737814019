import { COLORS } from "utils/constants/colors"
import SCORE_CONFIG from "assets/configs/score"

const colors = {
  orange: SCORE_CONFIG.common.colorsEvaluation.yellow,
  green: SCORE_CONFIG.common.colorsEvaluation.green,
  red: SCORE_CONFIG.common.colorsEvaluation.red,
  yellow: SCORE_CONFIG.common.colorsEvaluation.yellow2,
  grey: SCORE_CONFIG.common.disabledColor,
}

export const getAvgBarColor = (data, averages) => {
  return data.map((value, index) =>
    value > averages[index] ? colors.red : colors.green
  )
}

const STANDARD_COLORS = SCORE_CONFIG.common.colors

export const getStandardColors = (data) => {
  return data.map(
    (value, index) => STANDARD_COLORS[index % STANDARD_COLORS.length]
  )
}

const PILLAR_COLORS = {
  environmental: COLORS.planet.primary,
  social: COLORS.social.primary,
  economic: COLORS.profit.primary,
}

export function getColorByPillar(pillar) {
  return PILLAR_COLORS[pillar] || COLORS.planet.primary
}

export const colorBarComparative = (data) => {
  return data.map((value, index) => {
    if (value === Math.max(...data)) {
      return colors.orange
    } else if (value === Math.min(...data)) {
      return colors.green
    } else {
      return colors.orange
    }
  })
}

export const getCompareBarColors = (
  data,
  firstColumnAlwaysgrey = false,
  greyIfOnly1Column = false
) => {
  if (greyIfOnly1Column && data.every((value) => value <= 1)) {
    return colors.grey
  }

  const allEqual = data.every((value) => value === data[0])
  if (allEqual) return colors.green

  return data.map((value, index) => {
    if (firstColumnAlwaysgrey && index === 0) return colors.grey

    if (+value === Math.max(...data)) return colors.red
    if (+value === Math.min(...data)) return colors.green
    return colors.orange
  })
}

export const getFabAndUseCompareBarColors = (datasets, index) => {
  const comparativeIndex = index === 0 ? 1 : 0
  const currentData = datasets[index].values || datasets[index].data
  return currentData.map((value, i) => {
    const data =
      datasets[comparativeIndex].values || datasets[comparativeIndex].data
    const comparativeData = data[i]
    if (value > comparativeData) return colors.orange
    if (value < comparativeData) return COLORS.planet.primary
    return colors.green
  })
}
