import PropTypes from "prop-types"
import React from "react"
import { Badge } from "@shopify/polaris"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import CountryApp from "services/apps/countryApp"
import Unit from "views/atoms/reports/Unit"
import { convertValueToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_PER_ENERGY_UNITS, KG_PER_KWH } from "services/units/units"
import { round } from "services/units/round"

const ChartODD13EnergyMixMolecule = ({
  energyMixStatus,
  energyMixValue,
  country,
  title,
  based,
}) => {
  const { t } = useTranslation()

  /**
  @description Translate energy mix status to fit Polaris' Badge "status" prop
   */
  const getBadgeStatus = () => {
    switch (energyMixStatus) {
      case "good":
        return "success"

      case "medium":
        return "warning"

      case "bad":
        return "critical"

      default:
        return "new"
    }
  }

  /**
   @description Translate energy mix status
   */
  const getTranslation = () => {
    switch (energyMixStatus) {
      case "good":
        return t(`score:score.13.chartEnergyMix.status.good`)

      case "medium":
        return t(`score:score.13.chartEnergyMix.status.medium`)

      case "bad":
        return t(`score:score.13.chartEnergyMix.status.bad`)

      default:
        return t(`score:score.13.chartEnergyMix.status.unavailable`)
    }
  }

  const reference = (
    <Badge size="medium" status={getBadgeStatus()}>
      {getTranslation()}
    </Badge>
  )

  const convertedKpi = convertValueToReadable(
    energyMixValue,
    KG_PER_KWH,
    CO2_EMISSION_PER_ENERGY_UNITS
  )

  const content = {
    reference: reference,
    kpi_text: title || t(`score:score.13.chartEnergyMix.text`),
    translationPrefix_chartX: !title && `score:score.13.chartEnergyMix.`,
    kpi: round(convertedKpi.value),
    kpi_unit: (
      <Unit unit={t(`units:units.emissions_per_kwh.${convertedKpi.unit}`)} />
    ),
    sub_kpi: country && CountryApp.getNameI18n(country),
    sub_kpi_text: based ? (
      <>
        {based}
        <br />
      </>
    ) : null,
  }

  const content_for_display = <TextChartMinor content={content} />

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`score:score.13.chartEnergyMix.help`)}
    >
      <TextChart content={content_for_display}></TextChart>
    </HelpWidgetChartInteractiveMolecule>
  )
}

ChartODD13EnergyMixMolecule.propTypes = {
  energyMixStatus: PropTypes.string.isRequired,
  energyMixValue: PropTypes.number.isRequired,
  country: PropTypes.string.isRequired,
  title: PropTypes.string,
  based: PropTypes.string,
}

ChartODD13EnergyMixMolecule.defaultProps = {
  title: null,
}

export default ChartODD13EnergyMixMolecule
