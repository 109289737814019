import PropTypes from "prop-types"
import React, { useCallback } from "react"
import TextChartODD13EmissionsEmployeeMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsEmployeeMolecule"
import TextChartODD13GHGEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsMolecule"
import TextChartODD13EnergyMixMolecule from "views/molecules/canvases/charts/TextChartODD13EnergyMixMolecule"
import ChartODD13EquipmentsGHGEmissionsMolecule from "views/molecules/canvases/charts/ChartODD13EquipmentsGHGEmissionsMolecule"
import { NODE_TYPE_SITE } from "utils/constants/organization/nodeTypes"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import ChartODD13GHGEmissionsDistributionMolecule from "views/molecules/canvases/charts/ChartODD13GHGEmissionsDistributionMolecule"
import ChartODD13GHGEmissionsDistributionConsolidateMolecule from "views/molecules/canvases/charts/ChartODD13GHGEmissionsDistributionConsolidateMolecule"
import Chart3Sdg13Country from "views/molecules/canvases/Chart3SDG13Country"
import ChartODD13GHGEmissionOverviewMolecule from "views/molecules/canvases/charts/ChartODD13GHGEmissionOverviewMolecule"
import ChartODD13EmissionBreakdownPerRegionMolecule from "views/molecules/canvases/charts/ChartODD13EmissionBreakdownPerRegionMolecule"
import { useAppContext } from "components/AppContext"
import { useTranslation } from "react-i18next"
import { EnergyMixKpi } from "views/atoms/charts/kpi/energyMix/EnergyMixKpi"

const CountryOrSiteScoreLayoutOrganism = ({ score, isConsolidated }) => {
  const { t } = useTranslation()
  const { currentRootNode } = useAppContext()

  const chart4 = useCallback(
    () =>
      isConsolidated ? (
        <ChartODD13GHGEmissionsDistributionConsolidateMolecule score={score} />
      ) : (
        <ChartODD13GHGEmissionsDistributionMolecule score={score} />
      ),
    [score]
  )

  return (
    <ScoreLayoutOrganism
      maturity={score.odd13_maturity ?? 0}
      chart1={
        <TextChartODD13GHGEmissionsMolecule
          score={score}
          ghgEmissions={score?.odd13_CO2}
        />
      }
      chart2={
        <TextChartODD13EnergyMixMolecule
          energyMixStatus={score?.odd13_energy_mix_status}
          energyMixValue={
            currentRootNode?.methodology === "bilan_carbone"
              ? Number(score?.odd13_energy_mix_value)
              : Number(score?.odd7_market_based_emission_factor)
          }
          country={EnergyMixKpi(score).country}
          based={EnergyMixKpi(score).based}
        />
      }
      chart3={
        isConsolidated ? (
          <ChartODD13EmissionBreakdownPerRegionMolecule score={score} />
        ) : (
          <ChartODD13EquipmentsGHGEmissionsMolecule score={score} />
        )
      }
      chart4={chart4()}
      chart5={<TextChartODD13EmissionsEmployeeMolecule score={score} />}
      chart6={
        isConsolidated ? (
          <ChartODD13GHGEmissionOverviewMolecule score={score} />
        ) : (
          <Chart3Sdg13Country score={score} />
        )
      }
    />
  )
}

CountryOrSiteScoreLayoutOrganism.propTypes = {
  score: PropTypes.object.isRequired,
  isConsolidated: PropTypes.bool,
}

CountryOrSiteScoreLayoutOrganism.defaultProps = {
  isConsolidated: false,
}

export default CountryOrSiteScoreLayoutOrganism
