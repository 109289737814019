import React from "react"
import PropTypes from "prop-types"
import GHGScopesOrganism from "views/organisms/canvases/charts/ghg-scopes/GHGScopesOrganism"

const GHGAnalyzeOrganism = ({ envScore, methodology, based }) => {
  return (
    <GHGScopesOrganism
      score={envScore}
      methodology={methodology}
      based={based}
    />
  )
}

GHGAnalyzeOrganism.propTypes = {
  envScore: PropTypes.object.isRequired,
  methodology: PropTypes.string.isRequired,
  based: PropTypes.string.isRequired,
}

export default GHGAnalyzeOrganism
