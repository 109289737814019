import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import NodeOrganization from "services/organizations/NodeOrganization"
import MixedChartTemplate from "views/molecules/charts/template/MixedChartTemplate"

const ChartODD13DatacenterEquipmentAverageGHGMolecule = ({
  mixedChart = [],
}) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."

  const getLabels = mixedChart.map((node) =>
    NodeOrganization.getName(node.label, node.type, { countryShortName: true })
  )
  const bar = mixedChart.map((node) => node.bar)
  const line = mixedChart.map((node) => node.line)

  const labels = mixedChart.map((node) => {
    const label = NodeOrganization.getName(node.label, node.type, {
      countryShortName: true,
    })

    if (getLabels.filter((label) => label === node.label).length > 1) {
      return `${label} (${node.parent_names})`
    }

    return label
  })

  return (
    <MixedChartTemplate
      title={t(translationPrefix + "chartDatacenterEquipmentAverageGHG.title")}
      leftDataInputs={line}
      leftDataBarTitle={t(
        translationPrefix + "chartDatacenterEquipmentAverageGHG.legend.line"
      )}
      rightDataInputs={bar}
      rightDataBarTitle={t(
        translationPrefix + "chartDatacenterEquipmentAverageGHG.legend.bar"
      )}
      labelKeys={labels}
      leftUnitPath={`${translationPrefix}chartDatacenterEquipmentAverageGHG`}
      leftUnitKey="unit_left_axis"
      rightUnitPath={`units:units.emission`}
      rightUnitKey="t_unit"
      helpText={t(
        `${translationPrefix}chartDatacenterEquipmentAverageGHG.help`
      )}
      hideUnitOnBar
    />
  )
}

ChartODD13DatacenterEquipmentAverageGHGMolecule.propTypes = {
  mixedChart: PropTypes.array.isRequired,
}

export default ChartODD13DatacenterEquipmentAverageGHGMolecule
