import PropTypes from "prop-types"
import React from "react"
import TextChartODD13GHGEmissionsEmployeeMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsEmployeeMolecule"
import TextChartODD13GHGEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13GHGEmissionsMolecule"
import ChartODD13GHGEmissionsDistributionConsolidateMolecule from "views/molecules/canvases/charts/ChartODD13GHGEmissionsDistributionConsolidateMolecule"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import TextChartODD13HighestITDomainEmissionsMolecule from "views/molecules/canvases/charts/TextChartODD13HighestITDomainEmissionsMolecule"
import ChartODD13GHGEmissionsInternalExternalMolecule from "views/molecules/canvases/charts/ChartODD13GHGEmissionsInternalExternalMolecule"
import ChartODD13ConsolidateChart3 from "views/molecules/canvases/charts/ChartODD13ConsolidateChart3"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"
import { useTranslation } from "react-i18next"

const BrandScoreLayoutOrganism = ({ score }) => {
  const { t } = useTranslation()
  return (
    <ScoreLayoutOrganism
      maturity={score.odd13_maturity ?? 0}
      chart1={
        <TextChartODD13GHGEmissionsMolecule
          score={score}
          ghgEmissions={score?.odd13_CO2}
        />
      }
      chart2={<TextChartODD13GHGEmissionsEmployeeMolecule score={score} />}
      chart3={
        <DonutChartTemplate
          title={t("score:score.13.chartEmissionBreakdownPerRegion.title")}
          data={score?.emissions_breakdown?.data}
          labelKeys={score?.emissions_breakdown?.labels}
          unitPath="units:units.emission"
          unitKey={score?.emissions_breakdown?.unit_key}
          helpText={t(
            "score:score.13.chartGHGEmissionsPerEmployee.corporate.help"
          )}
        />
      }
      chart4={
        <ChartODD13GHGEmissionsDistributionConsolidateMolecule
          score={score}
          translationScope={"brand"}
        />
      }
      chart5={<TextChartODD13HighestITDomainEmissionsMolecule score={score} />}
      chart6={
        score?.odd13_ghg_internal_external_spit ? (
          <ChartODD13GHGEmissionsInternalExternalMolecule
            ghgSplit={score?.odd13_ghg_internal_external_spit}
          />
        ) : (
          <ChartODD13ConsolidateChart3 score={score} />
        )
      }
    />
  )
}

BrandScoreLayoutOrganism.propTypes = {
  score: PropTypes.object.isRequired,
  parent: PropTypes.object.isRequired,
}

export default BrandScoreLayoutOrganism
