import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ChartSDG13WaterConsumptionMolecule from "views/molecules/canvases/charts/websites/ChartSDG13WaterConsumptionMolecule"
import { round } from "services/units/round"
import MixedChartTemplate from "views/molecules/charts/template/MixedChartTemplate"

const ChartQuantitativeCo2ImpactsMolecule = ({
  mixedChart,
  defaultUnit,
  title,
}) => {
  const { t } = useTranslation()

  const labels = mixedChart?.map((websiteData) => websiteData.label)
  const bar = mixedChart?.map((websiteData) => round(websiteData.bar))
  const line = mixedChart?.map((websiteData) => websiteData.line)
  const barUnit = mixedChart?.[0]?.bar_unit || defaultUnit

  const barData = {
    label: t(
      "score:score.13.chartGHGWebsitesEmissions.modal.chartQuantitativeCO2Impacts.legend.bar"
    ),
    data: bar,
  }

  const lineData = {
    label: t(
      "score:score.13.chartGHGWebsitesEmissions.modal.chartQuantitativeCO2Impacts.legend.line"
    ),
    data: line,
  }

  return (
    <MixedChartTemplate
      title={title}
      leftDataInputs={lineData.data}
      leftDataBarTitle={lineData.label}
      rightDataInputs={barData.data}
      rightDataBarTitle={barData.label}
      labelKeys={labels}
      leftUnitPath={
        "score:score.13.chartGHGWebsitesEmissions.modal.chartQuantitativeCO2Impacts"
      }
      leftUnitKey={"unit_left_axis"}
      rightUnitPath={"units:units.emission"}
      rightUnitKey={barUnit}
      hideUnitOnBar
      showUnitInHover
      helpText={t(`analyze:websites.ghg_emissions.help`)}
    />
  )
}

ChartQuantitativeCo2ImpactsMolecule.propTypes = {
  mixedChart: PropTypes.array.isRequired,
  defaultUnit: PropTypes.string,
  title: PropTypes.string.isRequired,
}

ChartSDG13WaterConsumptionMolecule.defaultProps = {
  defaultUnit: "l_unit",
}

export default ChartQuantitativeCo2ImpactsMolecule
