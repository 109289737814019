import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import ChartBadge from "views/atoms/badges/ChartBadge"
import TextChartOdd7Pue from "views/molecules/canvases/charts/TextChartOdd7Pue"
import ChartOdd7Pue from "views/molecules/canvases/charts/ChartOdd7Pue"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"

import { safeDivision } from "utils/safeMath"
import { convertValueToReadable } from "services/units/valueConversion"
import { ENERGY_UNITS, MWH } from "services/units/units"
import { round } from "services/units/round"
import { WHITE_BORDER_DATASET } from "services/charts/datasets"
import { findColorByValue } from "views/molecules/canvases/charts/ChartODD13GHGEmissionsDistributionMolecule"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"
import CumulativeBarChartTemplate from "views/molecules/charts/template/CumulativeBarChartTemplate"
import ChartOdd7EnergyConsumptionPerProvider from "views/molecules/canvases/charts/websites/ChartOdd7EnergyConsumptionPerProvider"

const datasetKeyProvider = () => {
  return btoa(Math.random()).substring(0, 12)
}
const Objective7Score = ({ score }) => {
  const { t } = useTranslation()
  const firstLoad = useRef(true)

  const translationPrefix = "score:score.7."

  const TextToFormat = (text) =>
    text.split("\n").map((value, index) => {
      return (
        <span key={index}>
          {value}
          <br />
        </span>
      )
    })

  useEffect(() => {
    firstLoad.current = false
  })

  const chart1 = useCallback(() => {
    let energy_consumption = score["odd7_energy_consumption"]

    const convertedValue = convertValueToReadable(
      energy_consumption,
      MWH,
      ENERGY_UNITS,
      (value) => round(value, 1)
    )

    const content = {
      kpi: convertedValue.value,
      kpi_unit: t(`units:units.energy.${convertedValue.unit}`),
      kpi_text: t(translationPrefix + "chart1.text"),
      translationPrefix_chartX: translationPrefix + "chart1.",
    }

    const content_for_display = <TextChartMajor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart1.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart2 = useCallback(() => {
    const energy_IT = score["odd7_energy_consumption_IT_period_consumption"]
    const energy_green_IT = score["odd7_energy_green_IT"]
    const share_IT_green_energy_pre_round = safeDivision(
      energy_green_IT,
      energy_IT
    )
    const share_IT_green_energy = Math.round(share_IT_green_energy_pre_round)

    const badge = (
      <ChartBadge
        value={share_IT_green_energy}
        lowerLimit={40}
        upperLimit={70}
      />
    )

    const content = {
      reference: badge,
      kpi: share_IT_green_energy,
      kpi_unit: t(translationPrefix + "chart2.unit"),
      kpi_text: TextToFormat(t(translationPrefix + "chart2.text")),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart2.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart3 = () => {
    const { datasets } = score.odd7_use_vs_fab

    const data = datasets.map((dataset) => dataset.value)
    const labels = datasets.map((dataset) => dataset.label)
    const unit = datasets.map((dataset) => dataset.unit)

    return (
      <DonutChartTemplate
        title={t(`score:score.7.use_vs_fab.title`)}
        data={data}
        labelPath={"score:score.7.use_vs_fab.labels"}
        labelKeys={labels}
        unitPath="units:units.energy"
        unitKey={unit[0]}
        helpText={t("score:score.7.use_vs_fab.help")}
        disableOtherCatergory
      />
    )
  }

  const chart4 = () => {
    const { t } = useTranslation()

    const { datasets, label_keys, unit } = score?.odd7_energy_breakdown

    const translatedUnit = t(`units:units.energy.${unit}`)

    const chart4Dataset = datasets.map((dataset) => ({
      label: dataset.data_label_keys.map((data_label_key) =>
        t(`score:score.13.chartGHGEmissionsDistribution.${data_label_key}`)
      ),
      data: dataset.data,
      backgroundColor: dataset.data_before_conversion.map((value) =>
        findColorByValue(value, score.odd7_energy_consumption)
      ),
      unit: translatedUnit,
      stack: 1,
      showLabelInData: true,
      ...WHITE_BORDER_DATASET,
    }))

    return (
      <CumulativeBarChartTemplate
        title={t("score:score.7.energy_breakdown.title")}
        datasets={chart4Dataset}
        labelPath={"score:score.13.chartGHGEmissionsDistribution"}
        labelKeys={label_keys}
        unitPath={"units:units.energy"}
        unitKey={unit}
        hiddenLegend
        useBackgroundColorsInDatasets
        labelInDatasetsIsArray
        showLabelOnBar
        helpText={t(`score:score.7.energy_breakdown.help`)}
      />
    )
  }

  const chart6 = () => {
    if (score?.odd7_energy_consumption_per_provider) {
      return <ChartOdd7EnergyConsumptionPerProvider score={score} />
    }
    return <ChartOdd7Pue pueScores={score.pue_scores} />
  }

  return (
    <ScoreLayoutOrganism
      maturity={score.odd7_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={<TextChartOdd7Pue globalPueScore={score.pue_scores?.global} />}
      chart6={chart6()}
    />
  )
}

Objective7Score.propTypes = {
  score: PropTypes.shape({
    pue_scores: PropTypes.object.isRequired,
    odd7_maturity: PropTypes.number.isRequired,
    odd7_energy_consumption: PropTypes.number.isRequired,
    odd7_energy_consumption_fab: PropTypes.number.isRequired,
    odd7_energy_green_IT: PropTypes.number.isRequired,
    odd7_energy_consumption_IT_period_consumption: PropTypes.number.isRequired,
    ccf_usages: PropTypes.array.isRequired,
    odd7_use_vs_fab: PropTypes.object.isRequired,
    odd7_energy_breakdown: PropTypes.object.isRequired,
  }).isRequired,
  collect: PropTypes.object.isRequired,
}

export default Objective7Score
