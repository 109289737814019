import React from "react"
import { FormLayout } from "@shopify/polaris"
import { TextField } from "../../fields/CollectFields"
import Form from "../../Form"
import { Module } from "../../Module"
import { ELECTRICITY_CONSUMPTION_MODULE } from "utils/constants/collects/modules"
import { useFormConfig } from "../../FormContext"
import EnergyConsumptionTable from "views/organisms/collects/objectives/7/energy_consumption_inventories/EnergyConsumptionTable"
import { useTranslation } from "react-i18next"

const fields = [
  "energy_consumption_period_consumption",
  "energy_consumption_reuse_ratio",
]
const translationPath = "7.energy_consumption"

const EnergyConsumptionForm = () => {
  const { t } = useTranslation()
  useFormConfig({ fields, translationPath })

  return (
    <Form>
      <Module module={ELECTRICITY_CONSUMPTION_MODULE}>
        <FormLayout>
          <TextField id="energy_consumption_period_consumption" type="number" />
          <EnergyConsumptionTable
            label={t(
              "collect:questions.7.energy_consumption.energy_consumption_inventories.title"
            )}
          />
          <TextField id="energy_consumption_reuse_ratio" type="number" />
        </FormLayout>
      </Module>
    </Form>
  )
}

export default EnergyConsumptionForm
