export const G_CO2E = "g_unit"
export const KG_CO2E = "kg_unit"
export const T_CO2E = "t_unit"
export const KT_CO2E = "kt_unit"

export const CO2_EMISSION_UNITS = [G_CO2E, KG_CO2E, T_CO2E, KT_CO2E]

export const MG = "mass_mg_unit"
export const G = "mass_g_unit"
export const KG = "mass_kg_unit"
export const T = "mass_t_unit"
export const MASS_UNITS = [MG, G, KG, T]

export const WH = "wh_unit"
export const KWH = "kwh_unit"
export const MWH = "mwh_unit"
export const GWH = "gwh_unit"
export const TWH = "twh_unit"
export const ENERGY_UNITS = [WH, KWH, MWH, GWH, TWH]

export const M3 = "m3"
export const DAM3 = "dam3"
export const HAM3 = "ham3"
export const VOLUME_UNITS = [M3, DAM3, HAM3]

export const KB_UNIT = "kb_unit"
export const MB_UNIT = "mb_unit"
export const GB_UNIT = "gb_unit"
export const TB_UNIT = "tb_unit"
export const PB_UNIT = "pb_unit"
export const EB_UNIT = "eb_unit"
export const BYTE_UNITS = [KB_UNIT, MB_UNIT, GB_UNIT, TB_UNIT, PB_UNIT, EB_UNIT]

export const ML_UNIT = "ml_unit"
export const L_UNIT = "l_unit"
export const M3_UNIT = "m3_unit"
export const DAM3_UNIT = "dam3_unit"
export const HAM3_UNIT = "ham3_unit"
export const KM3_UNIT = "km3_unit"
export const VOLUME_UNITS_KEYS = [
  ML_UNIT,
  L_UNIT,
  M3_UNIT,
  DAM3_UNIT,
  HAM3_UNIT,
  KM3_UNIT,
]

export const EURO_UNIT = "euro_unit"
export const K_EURO_UNIT = "k_euro_unit"
export const M_EURO_UNIT = "m_euro_unit"
export const MONEY_UNITS_KEYS = [EURO_UNIT, K_EURO_UNIT, M_EURO_UNIT]

export const ZERO_COUNT_UNIT = "zero_unit"
export const K_COUNT_unit = "k_unit"
export const M_COUNT_UNIT = "m_unit"
export const B_COUNT_UNIT = "b_unit"
export const T_COUNT_UNIT = "t_unit"
export const COUNT_UNITS = [
  ZERO_COUNT_UNIT,
  K_COUNT_unit,
  M_COUNT_UNIT,
  B_COUNT_UNIT,
  T_COUNT_UNIT,
]

export const COUNT_NO_CONVERSION_UNITS = "hash"
export const COUNT_NO_CONVERSION_UNITS_KEYS = [COUNT_NO_CONVERSION_UNITS]

export const MG_PER_KWH = "mg_unit"
export const G_PER_KWH = "g_unit"
export const KG_PER_KWH = "kg_unit"
export const T_PER_KWH = "t_unit"
export const KT_PER_KWH = "kt_unit"
export const CO2_EMISSION_PER_ENERGY_UNITS = [
  MG_PER_KWH,
  G_PER_KWH,
  KG_PER_KWH,
  T_PER_KWH,
  KT_PER_KWH,
]

export const DETERMINE_UNITS = (unit) =>
  [
    CO2_EMISSION_UNITS,
    MASS_UNITS,
    ENERGY_UNITS,
    VOLUME_UNITS,
    BYTE_UNITS,
    VOLUME_UNITS_KEYS,
    COUNT_UNITS,
    COUNT_NO_CONVERSION_UNITS_KEYS,
    CO2_EMISSION_PER_ENERGY_UNITS,
  ].find((group) => group.includes(unit))
