import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Select } from "@shopify/polaris"

const SectorFieldAtom = ({
  property,
  value,
  type,
  error,
  onChange,
  disabled,
}) => {
  const [hasChanged, setHasChanged] = useState(false)
  const { t } = useTranslation()

  const getSectorOptions = () => {
    let options = []
    const sectors = t(`activation:company.questions.sector.options`, {
      returnObjects: true,
    })

    for (const sector of Object.keys(sectors)) {
      options.push({
        label: t(`activation:company.questions.sector.options.${sector}`),
        value: sector,
      })
    }

    return options
  }

  return (
    <>
      <Select
        label={t(`activation:${type}.questions.${property}.label`)}
        placeholder={t("collect:questions.common.default_select_placeholder")}
        id={property}
        name={property}
        value={value}
        error={error}
        onChange={(v) => {
          onChange(v)
          setHasChanged(true)
        }}
        options={getSectorOptions()}
        disabled={disabled}
      />
      {hasChanged && (
        <p
          style={{
            color: "red",
            marginTop: "0.5rem",
          }}
        >
          {t(
            `activation:${type}.questions.${property}.sector_change_consolidation_mode`
          )}
        </p>
      )}
    </>
  )
}

SectorFieldAtom.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

SectorFieldAtom.defaultProps = {
  value: null,
  node: null,
  error: null,
  disabled: false,
}

export default SectorFieldAtom
