import {
  CO2_EMISSION_UNITS,
  ENERGY_UNITS,
  KG,
  M3,
  MASS_UNITS,
  MWH,
  T_CO2E,
  VOLUME_UNITS,
} from "services/units/units"

export const ENVIRONMENTAL_CELLS = [
  {
    sdg: 13,
    maturity_field: "odd13_maturity",
    quantitative_field: "odd13_CO2",
    unit: T_CO2E,
    units: CO2_EMISSION_UNITS,
    unitType: "emission",
  },
  {
    sdg: 12,
    maturity_field: "odd12_maturity",
    quantitative_field: "odd12_sustainable_production_reuse_rate",
    quantitative_text: true,
    unit: "%",
  },
  {
    sdg: 15,
    maturity_field: "odd15_maturity",
    quantitative_field: "odd15_scarce_resources_avoided_waste",
    unit: KG,
    units: MASS_UNITS,
    unitType: "mass",
    quantitative_text: true,
    take_more_space: true,
  },
  {
    sdg: 7,
    maturity_field: "odd7_maturity",
    quantitative_field: "odd7_energy_consumption",
    unit: MWH,
    units: ENERGY_UNITS,
    take_more_space: true,
    unitType: "energy",
  },
  {
    sdg: 6,
    maturity_field: "odd6_maturity",
    quantitative_field: "odd6_water_consumption",
    unit: M3,
    units: VOLUME_UNITS,
    take_more_space: true,
  },
]

export const SOCIAL_CELLS = [
  {
    sdg: 3,
    maturity_field: "odd3_maturity",
    quantitative_field: "odd3_main_kpi",
    quantitative_text: true,
    unit: "%",
    take_more_space: true,
  },
  {
    sdg: 4,
    maturity_field: "odd4_maturity",
    quantitative_field: "odd4_education_green_employee_rate",
    quantitative_text: true,
    unit: "%",
    take_more_space: true,
  },
  {
    sdg: 5,
    maturity_field: "odd5_maturity",
    quantitative_field: "odd5_index_female_male",
    quantitative_text: true,
    unit: "",
    take_more_space: true,
  },
  {
    sdg: 10,
    maturity_field: "odd10_maturity",
    quantitative_field: "odd10_inclusion_KPI",
    quantitative_text: true,
    unit: "%",
    take_more_space: true,
  },
  {
    sdg: 1,
    maturity_field: "odd1_2_11_maturity",
    quantitative_field: "odd1_2_11_total_initiatives_number",
    quantitative_text: true,
    unit: "",
    take_more_space: true,
  },
  {
    sdg: 2,
    maturity_field: "odd1_2_11_maturity",
    quantitative_field: "odd1_2_11_total_initiatives_number",
    quantitative_text: true,
    unit: "",
    take_more_space: true,
  },
  {
    sdg: 11,
    maturity_field: "odd1_2_11_maturity",
    quantitative_field: "odd1_2_11_total_initiatives_number",
    quantitative_text: true,
    unit: "",
    take_more_space: true,
  },
]

export const PROFIT_CELLS = [
  {
    sdg: 8,
    maturity_field: "odd8_maturity",
    quantitative_field: "odd8_projects_sustainable_percentage",
    quantitative_text: true,
    unit: "%",
  },
  {
    sdg: 9,
    maturity_field: "odd9_maturity",
    quantitative_field: "odd9_innov_sustainable_percentage",
    quantitative_text: true,
    unit: "%",
  },
  {
    sdg: 16,
    maturity_field: "odd16_maturity",
    quantitative_field: "odd16_cdr_strategy_maturity",
    quantitative_text: true,
    unit: "",
  },
  {
    sdg: 17,
    maturity_field: "odd17_maturity",
    quantitative_field: "odd17_partners_good_sustainable_grade_percentage",
    quantitative_text: true,
    unit: "%",
  },
]
