import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import _ from "lodash"
import { Badge, Modal, Tabs } from "@shopify/polaris"
import ButtonIconMolecule from "views/molecules/interactive/ButtonIconMolecule"
import { useTranslation } from "react-i18next"
import {
  TAB_NODE_CENTRALIZATION,
  TAB_NODE_INFORMATION,
  TAB_NODE_MEMBERS,
} from "utils/constants/tabs/nodesTabContanst"
import ToastAtom from "views/atoms/forms/ToastAtom"
import {
  NODE_CATEGORY_CORPORATE,
  NODE_TYPE_COMPANY,
} from "utils/constants/organization/nodeTypes"
import NodeOrganization from "services/organizations/NodeOrganization"
import NodeFormModalOrganism from "views/organisms/forms/modal/NodeFormModalOrganism"
import MemberListModalOrganism from "views/organisms/organization/tables/MemberListModalOrganism"
import {
  FORM_FIELDS_TO_COMPONENTS,
  FORM_MODE_EDIT,
  NODE_TYPE_TO_STANDARD_FORM_FIELDS,
} from "utils/constants/forms/nodeForms"
import { useNodeContext } from "./NodeContext"
import DeleteConfirmationFormMolecule from "views/molecules/forms/confirmations/DeleteConfirmationFormMolecule"
import ConfirmationCardAtom from "../../molecules/forms/confirmations/ConfirmationCardAtom"
import CentralizationFormMolecule from "views/molecules/forms/centralizations/CentralizationFormMolecule"
import { TOAST_STATUS_NODE_BEING_DELETED } from "utils/constants/toastStatuses"

const isCentralizationDifferent = (
  newCentralization,
  initialCentralization
) => {
  if (newCentralization.length !== initialCentralization.length) return true

  return newCentralization.some(
    (newRule) => !_.find(initialCentralization, newRule)
  )
}

const CENTRALIZATION_EDIT_CONFIRMATION = "edit_centralization"
const PARENT_EDIT_CONFIRMATION = "edit_parent"
const NO_EDIT_CONFIRMATION = ""

function EditNodeOrganism({
  organizationNode,
  formFieldsToComponents,
  nodeTypeToFormFields,
  activator,
  reload,
}) {
  const { t } = useTranslation()

  const [isSaving, setIsSaving] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [editConfirmation, setEditConfirmation] = useState(NO_EDIT_CONFIRMATION)

  const [activeModal, setActiveModal] = useState(false)
  const [toastStatus, setToastStatus] = useState(0)
  const { node, initialNode, load, save, destroy } = useNodeContext()
  const [tabSelected, setTabSelected] = useState(TAB_NODE_INFORMATION)
  const [title, setTitle] = useState(<></>)

  const handleTabChange = useCallback(
    (selectedTabIndex) => setTabSelected(selectedTabIndex),
    []
  )

  const disabled = !organizationNode.can_edit

  const isProfitFrozen =
    node.centralized_policies_properties_frozen &&
    node.centralized_policies_properties_frozen.is_profit_frozen
  const isEnvFrozen =
    node.centralized_policies_properties_frozen &&
    node.centralized_policies_properties_frozen.is_env_frozen

  useEffect(() => {
    const name = NodeOrganization.getNodeName(node)
    setTitle(
      <>
        <span>
          {name} <Badge>{t(`dashboard:chart.types.${node?.type}`)}</Badge>
        </span>
        {(isProfitFrozen ||
          isEnvFrozen ||
          node.centralized_inventories_properties_frozen) && (
          <p
            style={{
              fontStyle: "italic",
              fontSize: "1.2rem",
              lineHeight: "1.5rem",
              marginTop: "0.5rem",
            }}
          >
            {t(`dashboard:modals.comments.centralized_parent_started_collect`)}
          </p>
        )}
      </>
    )
  }, [node])

  const handleChange = useCallback(() => {
    setTabSelected(TAB_NODE_INFORMATION)
    setActiveModal(!activeModal)
  }, [activeModal])

  /**
   * If user clicks on Edit button, call to API to retrieve the data of the node that has been selected
   */
  useEffect(async () => {
    if (!activeModal) return

    await load()
  }, [activeModal])

  const displayTab = () => {
    if (node) {
      switch (tabSelected) {
        case TAB_NODE_INFORMATION:
          return (
            <NodeFormModalOrganism
              setToastStatus={setToastStatus}
              disabled={disabled}
              mode={FORM_MODE_EDIT}
              formFieldsToComponents={formFieldsToComponents}
              nodeTypeToFormFields={nodeTypeToFormFields}
            />
          )

        case TAB_NODE_CENTRALIZATION:
          return <CentralizationFormMolecule disabled={disabled} />

        case TAB_NODE_MEMBERS:
          return (
            <MemberListModalOrganism
              nodeId={node.id}
              isExternal={organizationNode.is_external}
              disabled={!organizationNode.can_edit_memberships}
            />
          )
      }
    }
  }

  const displayPrimaryButton = useCallback(() => {
    if (disabled || tabSelected === TAB_NODE_MEMBERS) {
      return null
    }

    return {
      content: t("common:save"),
      onAction: editConfirmationOrSubmit,
      loading: isSaving,
    }
  }, [node, isSaving])

  const displaySecondaryButtons = useCallback(() => {
    if (disabled) {
      return null
    }

    const buttons = [
      {
        content: t("cancel"),
        onAction: () => handleChange(),
      },
    ]

    const isNotRootNode = node.type !== NODE_TYPE_COMPANY
    if (isNotRootNode && tabSelected !== TAB_NODE_MEMBERS && node.can_delete) {
      buttons.push({
        content: t("delete"),
        destructive: true,
        onAction: () => setDeleteConfirmation(true),
        loading: isDeleting,
      })
    }

    return buttons
  }, [node, tabSelected, isDeleting])

  const editConfirmationOrSubmit = () => {
    if (
      isCentralizationDifferent(node.centralization, initialNode.centralization)
    )
      return setEditConfirmation(CENTRALIZATION_EDIT_CONFIRMATION)

    const parentHasChanged =
      node.parent?.id && node.parent.id !== initialNode.parent.id
    const nodeCanBeCentralized =
      !node.light && node.category === NODE_CATEGORY_CORPORATE

    if (parentHasChanged && nodeCanBeCentralized)
      return setEditConfirmation(PARENT_EDIT_CONFIRMATION)

    return submit()
  }

  const submit = async () => {
    try {
      setIsSaving(true)

      await save(FORM_MODE_EDIT)
      setActiveModal(!activeModal)
      reload({ organization: true, currentNode: true })
    } catch (err) {
      // axios error are already handled by the NodeContext
      // so we only throw the error if it's not an axios error
      if (!err.isAxiosError) {
        throw err
      }
    } finally {
      setEditConfirmation(NO_EDIT_CONFIRMATION)
      setIsSaving(false)
    }
  }

  const destroyNode = async () => {
    try {
      setIsDeleting(true)

      await destroy()
      setActiveModal(!activeModal)
      setToastStatus(TOAST_STATUS_NODE_BEING_DELETED)
    } catch (err) {
      console.error(err)
    } finally {
      setIsDeleting(false)
    }
  }

  const displayTabs = () => {
    const tabs = [
      {
        id: `${TAB_NODE_INFORMATION}`,
        content: t(`dashboard:modals.tabs.information`),
        panelID: "TAB_NODE_INFORMATION",
      },
      {
        id: `${TAB_NODE_CENTRALIZATION}`,
        content: t(`dashboard:modals.tabs.centralization`),
        panelID: "TAB_NODE_CENTRALIZATION",
      },
    ]

    if (organizationNode?.can_read) {
      tabs.push({
        id: `${TAB_NODE_MEMBERS}`,
        content: t(`dashboard:modals.tabs.members`),
        panelID: "TAB_NODE_MEMBERS",
      })
    }

    return tabs
  }

  const modalContent = useCallback(() => {
    if (deleteConfirmation)
      return (
        <DeleteConfirmationFormMolecule
          onActionCancel={setDeleteConfirmation}
          onActionDelete={destroyNode}
        />
      )
    if (editConfirmation !== NO_EDIT_CONFIRMATION)
      return (
        <ConfirmationCardAtom
          cancel={() => setEditConfirmation(NO_EDIT_CONFIRMATION)}
          validate={submit}
        >
          {t(`dashboard:chart.buttons.${editConfirmation}`)}
        </ConfirmationCardAtom>
      )

    return node && displayTab()
  }, [deleteConfirmation, editConfirmation, node, tabSelected])

  return (
    <>
      <ToastAtom
        toastStatus={toastStatus}
        setToastStatus={setToastStatus}
        duration={7500}
      />

      <Modal
        activator={
          activator && (
            <activator.Component
              {...activator.props}
              onClick={() => setActiveModal(true)}
            >
              {activator.text}
            </activator.Component>
          )
        }
        open={activeModal}
        onClose={handleChange}
        title={title}
        primaryAction={displayPrimaryButton()}
        secondaryActions={displaySecondaryButtons()}
      >
        <section className={node?.is_external ? "external" : ""}>
          <Tabs
            tabs={displayTabs()}
            selected={tabSelected}
            onSelect={handleTabChange}
            fitted
          />
          <section style={{ overflowY: "scroll", height: "475px" }}>
            <Modal.Section>{modalContent()}</Modal.Section>
          </section>
        </section>
      </Modal>
    </>
  )
}

EditNodeOrganism.propTypes = {
  organizationNode: PropTypes.object.isRequired,
  formFieldsToComponents: PropTypes.object.isRequired,
  nodeTypeToFormFields: PropTypes.object.isRequired,
  activator: PropTypes.object,
  reload: PropTypes.func,
}

EditNodeOrganism.defaultProps = {
  formFieldsToComponents: FORM_FIELDS_TO_COMPONENTS,
  nodeTypeToFormFields: NODE_TYPE_TO_STANDARD_FORM_FIELDS,
  activator: {
    Component: ButtonIconMolecule,
    props: {
      iconName: "EditMajor",
    },
    text: null,
  },
  reload: (_) => _,
}
export default EditNodeOrganism
