import React from "react"
import { PropTypes } from "prop-types"
import { useTranslation } from "react-i18next"
import ChartRadarMolecule from "views/molecules/canvases/charts/common/ChartRadarMolecule"
import ITDomainAnalysisLayoutOrganism from "views/organisms/canvases/charts/ITDomainAnalysisLayoutOrganism"
import CompareKpiMolecule from "views/molecules/organization/kpis/CompareKpiMolecule"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import {
  compareKpiProps,
  statusPropTypes,
} from "views/organisms/canvases/charts/analyzes/historization/HistoryPropTypes"
import ChartLineMolecule from "views/molecules/canvases/charts/common/ChartLineMolecule"
import { STATUS_NEW } from "views/atoms/canvases/charts/BadgeStatusChartAtom"
import CumulativeBarChartTemplate from "views/molecules/charts/template/CumulativeBarChartTemplate"
import { COMPARE_LEGENDS_DECREASE_AND_INCREASE } from "services/charts/legend"


export const chart4Options = {
  tooltips: {
    callbacks: {
      title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
      label: (tooltipItem, data) => {
        const periodName = data.datasets[tooltipItem.datasetIndex].label
        return `${periodName}: ${tooltipItem.value}`
      },
    },
  },
}

const CHART_2_PERCENTAGE_THRESHOLD = 200

const chart2GetValue = ({ ratio, percentage }) =>
  percentage > CHART_2_PERCENTAGE_THRESHOLD ? ratio : percentage

const chart2GetUnit = ({ percentage }) =>
  percentage > CHART_2_PERCENTAGE_THRESHOLD ? "x" : "%"

const isChart2UnitLeft = ({ percentage }) =>
  percentage > CHART_2_PERCENTAGE_THRESHOLD

const HistorizationAnalyzeChartsOrganism = ({
  maturity,
  maturityIndexesesChartData,
  ghgEvolutionChartData,
  ghgBreakdownEvolutionChartData,
  kpiMaturityEvolution,
  kpiBiggestGhgEvolution,
  kpiBiggestThemeMaturityEvolution,
}) => {
  const { t } = useTranslation()
  const transSuffix = "analyze:historization.node."

  const biggestChangeTheme = () => {
    const { pillar, odd, theme } = kpiBiggestThemeMaturityEvolution

    return t(
      `collect:pillars.${pillar}.objectives.${odd}.themes.${theme}.title`
    )
  }

  const chart2 = () => {
    if (kpiBiggestGhgEvolution.status === STATUS_NEW) {
      return (
        <CompareKpiMolecule
          subtitle={t(transSuffix + "kpi.biggest_ghg_evolution.title")}
          freeText={t(
            `score:score.13.chartGHGEmissionsDistribution.${kpiBiggestGhgEvolution.name}`
          )}
          status={kpiBiggestGhgEvolution.status}
          current={kpiBiggestGhgEvolution.difference}
          currentUnit={t(
            `units:units.emission.${kpiBiggestGhgEvolution.unit_key}`
          )}
        />
      )
    }

    return (
      <CompareKpiMolecule
        subtitle={t(transSuffix + "kpi.biggest_ghg_evolution.title")}
        current={chart2GetValue(kpiBiggestGhgEvolution)}
        freeText={t(
          `score:score.13.chartGHGEmissionsDistribution.${kpiBiggestGhgEvolution.name}`
        )}
        difference={kpiBiggestGhgEvolution.difference}
        status={kpiBiggestGhgEvolution.status}
        currentUnit={chart2GetUnit(kpiBiggestGhgEvolution)}
        currentUnitToLeft={isChart2UnitLeft(kpiBiggestGhgEvolution)}
        differenceUnit={t(
          `units:units.emission.${kpiBiggestGhgEvolution.unit_key}`
        )}
      />
    )
  }

  const chart6Datasets = ghgBreakdownEvolutionChartData.datasets.map((dataset) => {
    return {
      data: dataset.data.emissions,
      label: dataset.data.type,
    }
  })

  return (
    <ITDomainAnalysisLayoutOrganism
      maturity={maturity}
      pillar={PILLAR_ENVIRONMENTAL}
      transSuffix={transSuffix}
      chart1={
        <CompareKpiMolecule
          title={t(transSuffix + "kpi.pillar_maturity.title")}
          current={`${kpiMaturityEvolution.difference > 0 ? "+" : ""} ${
            kpiMaturityEvolution.difference
          }`}
        />
      }
      chart2={chart2()}
      chart3={
        <ChartLineMolecule
          title={t(transSuffix + "charts.ghg_evolution.title")}
          label={t(transSuffix + "charts.ghg_evolution.title")}
          config={ghgEvolutionChartData}
        />
      }
      chart4={
        <ChartRadarMolecule
          title={t(transSuffix + "charts.maturity_indexes_evolution.title")}
          config={maturityIndexesesChartData}
          options={chart4Options}
          pillarId={PILLAR_ENVIRONMENTAL}
        />
      }
      chart5={
        <CompareKpiMolecule
          subtitle={t(transSuffix + "kpi.biggest_theme_difference.title")}
          freeText={biggestChangeTheme()}
          current={`${
            kpiBiggestThemeMaturityEvolution.difference > 0 ? "+" : ""
          } ${kpiBiggestThemeMaturityEvolution.difference}`}
          status={kpiBiggestThemeMaturityEvolution.status}
        />
      }
      chart6={
       <CumulativeBarChartTemplate
          title={t(transSuffix + "charts.ghg_breakdown_evolution.title")}
          datasets={chart6Datasets}
          labelKeys={ghgBreakdownEvolutionChartData.labels}
          unitPath="units:units.emission"
          unitKey={ghgBreakdownEvolutionChartData.unit_key}
          modifiedLegend={COMPARE_LEGENDS_DECREASE_AND_INCREASE}
          showLabelOnBar
          compareBarColors
          firstColumnAlwaysGray
          helpText=""
       />
      }
    />
  )
}

HistorizationAnalyzeChartsOrganism.propTypes = {
  ghgEvolutionChartData: PropTypes.object.isRequired,
  kpiMaturityEvolution: compareKpiProps.isRequired,
  ghgBreakdownEvolutionChartData: PropTypes.object.isRequired,
  maturityIndexesesChartData: PropTypes.object.isRequired,
  maturity: PropTypes.number.isRequired,
  kpiBiggestGhgEvolution: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
    difference: PropTypes.number,
    status: statusPropTypes,
    unit_key: PropTypes.string.isRequired,
  }).isRequired,
  kpiBiggestThemeMaturityEvolution: PropTypes.shape({
    value: PropTypes.number,
    difference: PropTypes.number,
    status: statusPropTypes,
    odd: PropTypes.string,
    pillar: PropTypes.string,
    theme: PropTypes.string,
  }).isRequired,
}

export default HistorizationAnalyzeChartsOrganism
