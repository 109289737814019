export const yAxes = (unit) => {
  return {
    scales: {
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: !unit.includes("null"),
            labelString: unit,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }
}
