import React from "react"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

const ChartODD13SplitBetweenScopeMolecule = ({
  score,
  methodology,
  helpText,
  based,
}) => {
  const { t } = useTranslation()
  const getDatasets = (score, methodology) => {
    const datasets =
      score[`env_score_${methodology}_${based}`].split_scopes_datasets
    const data = datasets.map((data) => data.data || 0)
    const labelKeys = datasets.map((data) => data.label)
    const unitKey = datasets[0].unit

    return { data, labelKeys, unitKey }
  }

  const { data, labelKeys, unitKey } = getDatasets(score, methodology)

  return (
    <DonutChartTemplate
      title={t(`analyze:ghg_scopes.chartODD13SplitBetweenScopeMolecule.title`)}
      data={data}
      labelPath={
        "analyze:ghg_scopes.chartODD13ScopePerSubScopeBreakdownMolecule.label_keys"
      }
      labelKeys={labelKeys}
      unitPath="units:units.emission"
      unitKey={unitKey}
      helpText={helpText}
      desactivateOtherCategories
    />
  )
}

ChartODD13SplitBetweenScopeMolecule.propTypes = {
  score: PropTypes.object,
  methodology: PropTypes.oneOf(["ghg_protocol", "bilan_carbone"]).isRequired,
  helpText: PropTypes.string.isRequired,
  based: PropTypes.oneOf(["market_based", "location_based"]).isRequired,
}

export default ChartODD13SplitBetweenScopeMolecule
