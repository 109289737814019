import React, { useCallback } from "react"
import { useParams } from "react-router-dom"
import PositiveImpactForm from "components/widgets/CollectPage/forms/pillars/PositiveImpactForm"
import EnergyConsumptionForm from "./Objective7EnergyConsumptionForm"

const Objective7Form = () => {
  const { themeId } = useParams()

  const themeFormMarkup = useCallback(() => {
    switch (themeId) {
      case "energy_consumption":
        return <EnergyConsumptionForm />
      case "positive_impact":
        return <PositiveImpactForm />
      default: {
        return <p>Aucun formulaire pour ce thème.</p>
      }
    }
  }, [themeId])

  return themeFormMarkup()
}

export default Objective7Form
