import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import DonutChartTemplate from "views/molecules/charts/template/DonutChartTemplate"

const ChartAnalyzeWebsiteSplitPerItDomain = ({ websiteScore }) => {
  const { t } = useTranslation()
  const { labels, datasets } = websiteScore.split_per_it_domain
  return (
    <DonutChartTemplate
      title={t("score:score.13.chartGHGEmissionsDistribution.title")}
      data={datasets[0].data}
      labelPath={"analyze:websites.split_per_it_domain"}
      labelKeys={labels}
      unitPath={"units:units.emission"}
      unitKey="kg_unit"
      helpText={t("analyze:websites.split_per_it_domain.help")}
    />
  )
}

ChartAnalyzeWebsiteSplitPerItDomain.propTypes = {
  websiteScore: PropTypes.object.isRequired,
}

export default ChartAnalyzeWebsiteSplitPerItDomain
