import {
  INVENTORY_BUDGETS,
  INVENTORY_BUSINESS_ASSETS_INVENTORIES,
  INVENTORY_CLOUD,
  INVENTORY_DATA_CENTER_CLOUD_INVENTORIES,
  INVENTORY_DATA_CENTER_EXTERNAL_INVENTORIES,
  INVENTORY_DATA_CENTER_INTERNAL_INVENTORIES,
  INVENTORY_EXTERNAL_APPS,
  INVENTORY_INNOVATIONS,
  INVENTORY_INTERNAL_APPS,
  INVENTORY_NETWORK_EQUIPMENT_INVENTORIES,
  INVENTORY_PARTNERS,
  INVENTORY_PROJECTS,
  INVENTORY_SOCIAL_PEOPLE_INVENTORIES,
  INVENTORY_TECH_TEAM_INVENTORIES,
  INVENTORY_VIRTUAL_MACHINES,
  INVENTORY_WEBSITES,
  INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES,
  INVENTORY_SOLD_WORKPLACE_EQUIPMENT_INVENTORIES,
} from "utils/constants/centralization/inventoryItems"
import {
  POLICY_AI_PERSONAL_DATA,
  POLICY_CDR,
  POLICY_CSR,
  POLICY_CYBER_SECURITY,
  POLICY_ENV_COLLECT,
  POLICY_ETHICS,
  POLICY_PARTNERSHIP_INTERNATIONAL,
  POLICY_PARTNERSHIP_NATIONAL,
  POLICY_PARTNERSHIP_PRACTICES,
  POLICY_PARTNERSHIP_REGIONAL,
  POLICY_SOCIAL_COLLECT,
} from "utils/constants/centralization/policyItems"
import {
  NODE_TYPE_BRAND,
  NODE_TYPE_COMPANY,
  NODE_TYPE_COUNTRY,
  NODE_TYPE_REGION,
} from "utils/constants/organization/nodeTypes"
import {
  PILLAR_ENVIRONMENTAL,
  PILLAR_PROFIT,
  PILLAR_SOCIAL,
} from "utils/constants/pillars"
import {
  BYOD_MODULE,
  ELECTRICITY_CONSUMPTION_MODULE,
  ELECTRONIC_WASTE_MODULE,
  MAIL_COLLABORATION_MODULE,
  POSITIVE_IMPACT_MODULES,
  PRINTING_MODULE,
  TELCO_MODULE,
  WATER_CONSUMPTION_MODULE,
} from "utils/constants/collects/modules"

export const CENTRALIZATION_INVENTORY = "centralization_inventory"
export const CENTRALIZATION_POLICY = "centralization_policy"
export const CENTRALIZATION_FULL = "centralization_full"

export const INVENTORY_TYPES = [
  INVENTORY_EXTERNAL_APPS,
  INVENTORY_INTERNAL_APPS,
  INVENTORY_VIRTUAL_MACHINES,
  INVENTORY_NETWORK_EQUIPMENT_INVENTORIES,
  INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES,
  INVENTORY_SOLD_WORKPLACE_EQUIPMENT_INVENTORIES,
  INVENTORY_TECH_TEAM_INVENTORIES,
  INVENTORY_SOCIAL_PEOPLE_INVENTORIES,
  INVENTORY_DATA_CENTER_EXTERNAL_INVENTORIES,
  INVENTORY_DATA_CENTER_INTERNAL_INVENTORIES,
  INVENTORY_WEBSITES,
  INVENTORY_DATA_CENTER_CLOUD_INVENTORIES,
  INVENTORY_BUDGETS,
  INVENTORY_PROJECTS,
  INVENTORY_INNOVATIONS,
  INVENTORY_PARTNERS,
]

export const POLICY_TYPES = [
  POLICY_ENV_COLLECT,
  POLICY_SOCIAL_COLLECT,
  POLICY_CSR,
  POLICY_CDR,
  POLICY_ETHICS,
  POLICY_CYBER_SECURITY,
  POLICY_PARTNERSHIP_INTERNATIONAL,
  POLICY_PARTNERSHIP_REGIONAL,
  POLICY_PARTNERSHIP_NATIONAL,
  POLICY_AI_PERSONAL_DATA,
  POLICY_PARTNERSHIP_PRACTICES,
  WATER_CONSUMPTION_MODULE,
  ELECTRICITY_CONSUMPTION_MODULE,
  TELCO_MODULE,
  PRINTING_MODULE,
  ELECTRONIC_WASTE_MODULE,
  MAIL_COLLABORATION_MODULE,
  BYOD_MODULE,
  ...POSITIVE_IMPACT_MODULES,
]

export const CENTRALIZATION_TYPES = [...INVENTORY_TYPES, ...POLICY_TYPES]

export const CENTRALIZABLE_NODES = [
  NODE_TYPE_COMPANY,
  NODE_TYPE_BRAND,
  NODE_TYPE_REGION,
  NODE_TYPE_COUNTRY,
]

export const ENV_CENTRALIZATION_TYPES = [
  INVENTORY_EXTERNAL_APPS,
  INVENTORY_NETWORK_EQUIPMENT_INVENTORIES,
  INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES,
  INVENTORY_SOLD_WORKPLACE_EQUIPMENT_INVENTORIES,
  INVENTORY_BUSINESS_ASSETS_INVENTORIES,
  INVENTORY_TECH_TEAM_INVENTORIES,
  INVENTORY_SOCIAL_PEOPLE_INVENTORIES,
  INVENTORY_DATA_CENTER_EXTERNAL_INVENTORIES,
  INVENTORY_DATA_CENTER_INTERNAL_INVENTORIES,
  INVENTORY_WEBSITES,
  INVENTORY_CLOUD,
  WATER_CONSUMPTION_MODULE,
  ELECTRICITY_CONSUMPTION_MODULE,
  TELCO_MODULE,
  PRINTING_MODULE,
  ELECTRONIC_WASTE_MODULE,
  MAIL_COLLABORATION_MODULE,
  BYOD_MODULE,
  ...POSITIVE_IMPACT_MODULES,
]

export const SOCIAL_CENTRALIZATION_TYPES = [POLICY_SOCIAL_COLLECT]

export const PROFIT_CENTRALIZATION_TYPES = [
  POLICY_CSR,
  POLICY_CDR,
  POLICY_ETHICS,
  POLICY_CYBER_SECURITY,
  POLICY_PARTNERSHIP_INTERNATIONAL,
  POLICY_PARTNERSHIP_REGIONAL,
  POLICY_PARTNERSHIP_NATIONAL,
  INVENTORY_BUDGETS,
  INVENTORY_PROJECTS,
  INVENTORY_INNOVATIONS,
  INVENTORY_PARTNERS,
  POLICY_AI_PERSONAL_DATA,
  POLICY_PARTNERSHIP_PRACTICES,
]

export const PILLAR_TO_CENTRALIZATION_TYPES = {
  [PILLAR_ENVIRONMENTAL]: ENV_CENTRALIZATION_TYPES,
  [PILLAR_SOCIAL]: SOCIAL_CENTRALIZATION_TYPES,
  [PILLAR_PROFIT]: PROFIT_CENTRALIZATION_TYPES,
}

export const CENTRALIZATION_METHODOLOGIES = ["bilan_carbone", "ghg_protocol"]

export const CENTRALIZATION_CONSOLIDATION_MODES = [
  "equity_share",
  "operational_control",
]
