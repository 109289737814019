import React, { useEffect, useState } from "react"
import { Button, Icon, Label } from "@shopify/polaris"
import { CirclePlusMinor, EditMinor, ViewMinor } from "@shopify/polaris-icons"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import useFieldIsLocked from "components/widgets/CollectPage/forms/fields/useFieldIsLocked"
import EnergyConsumptionModal from "views/organisms/collects/objectives/7/energy_consumption_inventories/EnergyConsumptionModal"
import { useEntity } from "hooks/useAxios"
import EnergyConsumptionInventoryEntity from "services/entities/EnergyConsumptionInventoryEntity"
import EnergyConsumptionTableEmptyBody from "views/organisms/collects/objectives/7/energy_consumption_inventories/EnergyConsumptionTableEmptyBody"
import CountryApp from "services/apps/countryApp"

const translationPrefix =
  "collect:questions.7.energy_consumption.energy_consumption_inventories.columns."

const COLUMNS = [
  "category",
  "name",
  "provider",
  "provider_country",
  "provider_emission_factor",
  "energy_consumption",
  "energy_type",
  "it_staff_in_building_ratio",
]

const COLUMNS_IN_INTERNAL_HOSTING = [
  "provider",
  "provider_country",
  "provider_emission_factor",
  "energy_consumption",
  "energy_type",
]

const EnergyConsumptionTable = ({ label, dataCenterInternalInventoryId }) => {
  const { t } = useTranslation()
  const { isLocked } = useFieldIsLocked()
  const [open, setOpen] = useState(false)
  const [
    energyConsumptionInventories,
    setEnergyConsumptionInventories,
  ] = useState([])
  const [formData, setFormData] = useState(null)

  const energyConsumptionInventoryEntity = useEntity(
    EnergyConsumptionInventoryEntity
  )

  const loadEnergyConsumptionInventories = async () => {
    const { data } = await energyConsumptionInventoryEntity.index({
      data_center_internal_inventory_id: dataCenterInternalInventoryId,
    })

    setEnergyConsumptionInventories(data)
  }

  useEffect(loadEnergyConsumptionInventories, [])

  const columns = dataCenterInternalInventoryId
    ? COLUMNS_IN_INTERNAL_HOSTING
    : COLUMNS

  const handleCellValue = (column, value) => {
    if (column === "provider_country") {
      return CountryApp.getNameI18n(value)
    }

    if (column === "category") {
      return t(
        `collect:questions.7.energy_consumption.energy_consumption_inventories.category.${value}`
      )
    }

    if (column === "energy_type") {
      return value
        ? t(
            `collect:questions.7.energy_consumption.energy_consumption_inventories.energy_types.${value}`
          )
        : ""
    }

    if (column === "it_staff_in_building_ratio") {
      return value ? `${+value}%` : ""
    }

    if (column === "energy_consumption") {
      return `${+value} ${t("units:units.energy.kwh_unit")}`
    }

    if (column === "provider_emission_factor") {
      return value ? `${+value} gCO2e/kWh` : ""
    }

    return value
  }

  return (
    <>
      <Label>{label}</Label>
      <table className="Verdikt-DynamicTable">
        <thead>
          <tr className="leftAligned">
            {columns.map((column) => (
              <th key={column}>{t(translationPrefix + column)}</th>
            ))}
            {!isLocked && (
              <th>
                <Button
                  plain
                  onClick={() => {
                    setFormData(null)
                    setOpen(true)
                  }}
                >
                  <Icon source={CirclePlusMinor} />
                </Button>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {energyConsumptionInventories.map((energyConsumptionInventory) => (
            <tr key={energyConsumptionInventory.id}>
              <>
                {columns.map((column) => (
                  <td key={column}>
                    {handleCellValue(
                      column,
                      energyConsumptionInventory[column]
                    )}
                  </td>
                ))}
                <td>
                  <Button
                    plain
                    onClick={() => {
                      setFormData(energyConsumptionInventory)
                      setOpen(true)
                    }}
                  >
                    <Icon source={isLocked ? ViewMinor : EditMinor} />
                  </Button>
                </td>
              </>
            </tr>
          ))}

          {energyConsumptionInventories.length === 0 && (
            <EnergyConsumptionTableEmptyBody
              colSpan={columns.length + 1}
              isLocked={isLocked}
              setOpen={(value) => {
                setFormData(null)
                setOpen(value)
              }}
            />
          )}
        </tbody>
      </table>
      <EnergyConsumptionModal
        open={open}
        setOpen={setOpen}
        disabled={isLocked}
        onSaved={loadEnergyConsumptionInventories}
        updateFormData={formData}
        dataCenterInternalInventoryId={dataCenterInternalInventoryId}
      />
    </>
  )
}

EnergyConsumptionTable.propTypes = {
  label: PropTypes.string.isRequired,
  dataCenterInternalInventoryId: PropTypes.number,
}

EnergyConsumptionTable.defaultProps = {
  dataCenterInternalInventoryId: null,
}

export default EnergyConsumptionTable
