import SdgIcon from "assets/images/sdg"
import classNames from "classnames/bind"
import ProgressBar from "components/widgets/ProgressBar"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { Link } from "react-router-dom"
import "stylesheets/ObjectiveTabs.scss"
import { OBJECTIVES_THEMES, PILLARS_OBJECTIVES } from "utils/constants/pillars"
import { useAppContext } from "components/AppContext"

const ObjectiveTabs = ({ pageName, pillar, objective, progress, collect }) => {
  const { currentRootNode, currentOrganization } = useAppContext()
  const objectives = PILLARS_OBJECTIVES[pillar]

  const isObjectiveIn1211 = useCallback(
    () => ["1", "2", "11"].includes(objective),
    [objective]
  )

  const objectiveListMarkup = useCallback(() => {
    return objectives.map((objectiveId, i) => {
      if (pageName === "analyze" && objectiveId !== 13) {
        return
      }

      /*
      get themes associated to the odd, get all the activated themes, compare the two arrays
      */

      const objectiveThemes = OBJECTIVES_THEMES[objectiveId]

      const isActivated =
        (currentRootNode.is_corporate && !currentRootNode.is_leaser) ||
        _.some(objectiveThemes, (theme) =>
          collect?.activated_themes?.includes(
            theme === "positive_impact" ? `odd${objectiveId}_${theme}` : theme
          )
        )

      const showProgressBar =
        isActivated ||
        (currentRootNode.is_corporate &&
          !currentOrganization?.is_partial_partner &&
          !currentRootNode.is_leaser)

      return (
        <li key={i}>
          <Link to={`/${pageName}/${pillar}/${objectiveId}`}>
            <button
              className={classNames(pillar, {
                selected: objective == objectiveId,
                activated: isActivated,
                selected_three:
                  [1, 2, 11].includes(objectiveId) && isObjectiveIn1211(),
              })}
            >
              <SdgIcon id={objectiveId} />
              {progress && showProgressBar && (
                <ProgressBar
                  progress={progress}
                  pillarId={pillar}
                  objectiveId={`${objectiveId}`}
                />
              )}
            </button>
          </Link>
        </li>
      )
    })
  }, [pillar, objective, progress])

  return objectives ? (
    <ul className="objectivesMenu">{objectiveListMarkup()}</ul>
  ) : null
}

ObjectiveTabs.propTypes = {
  pageName: PropTypes.string.isRequired,
  pillar: PropTypes.string.isRequired,
  objective: PropTypes.string,
  progress: PropTypes.object,
  collect: PropTypes.object,
}

export default ObjectiveTabs
