import React, { useCallback } from "react"
import ITDomainAnalysisLayoutOrganism from "views/organisms/canvases/charts/ITDomainAnalysisLayoutOrganism"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import Unit from "views/atoms/reports/Unit"
import NumberFormatterApp from "services/apps/numberFormatterApp"
import { EMISSION_UNIT } from "utils/constants/numbers"
import _ from "lodash"
import Config from "assets/configs/score"
import {
  KPI_STATUS_DISABLED,
  KPI_STATUS_DOWN,
  KPI_STATUS_NEUTRAL,
  KPI_STATUS_UP,
} from "utils/constants/analyze/kpi/kpiStatus"
import { round } from "services/units/round"
import { compareNodeLabels } from "services/score/charts/labels/compareNodeLabels"
import { useAppContext } from "components/AppContext"
import ChartRadarMolecule from "views/molecules/canvases/charts/common/ChartRadarMolecule"
import { chart4Options } from "views/organisms/canvases/charts/analyzes/historization/HistorizationAnalyzeChartsOrganism"
import BarChartTemplate from "views/molecules/charts/template/BarChartTemplate"
import { COMPARE_LEGENDS } from "services/charts/legend"
import RadarChartTemplate from "views/molecules/charts/template/RadarChartTemplate"
import CumulativeBarChartTemplate from "views/molecules/charts/template/CumulativeBarChartTemplate"
import CompareWebsiteOnlyChar3 from "views/molecules/charts/compare/CompareWebsiteOnlyChar3"

const prefixTranslation = "analyze:compare."

const chart3statusColors = {
  [KPI_STATUS_UP]: Config.common.colorsEvaluation.green,
  [KPI_STATUS_NEUTRAL]: Config.common.colorsEvaluation.yellow2,
  [KPI_STATUS_DOWN]: Config.common.colorsEvaluation.red,
  [KPI_STATUS_DISABLED]: Config.common.disabledColor,
}
const chart3statusColor = (status) => chart3statusColors[status]

const colors = {
  lowest: {
    backgroundColor: "rgba(100, 164, 108, 0.5)",
    borderColor: Config.common.colorsEvaluation.green,
    pointBackgroundColor: Config.common.colorsEvaluation.green,
  },
  medium: {
    backgroundColor: "rgba(255, 218, 32, 0.5)",
    borderColor: Config.common.colorsEvaluation.yellow,
    pointBackgroundColor: Config.common.colorsEvaluation.yellow,
  },
  highest: {
    backgroundColor: "rgba(199, 0, 57, 0.5)",
    borderColor: Config.common.colorsEvaluation.red,
    pointBackgroundColor: Config.common.colorsEvaluation.red,
  },
}

const colorByPosition = (hasFirstPosition, hasLastPosition, hasOneNode) => {
  if (hasFirstPosition) return colors.lowest
  if (hasLastPosition && hasOneNode) return colors.medium
  if (hasLastPosition) return colors.highest
  return colors.medium
}

const NodeAnalysesOrganism = ({ comparedScores, comparedNodes }) => {
  const { currentNode } = useAppContext()
  const { t } = useTranslation()

  const hasOneNode = comparedNodes.length === 1

  const chart1 = useCallback(() => {
    const { datasets, unit_key } = comparedScores.chart1

    const data = datasets.map(({ value }) => {
      return value
    })
    const label = datasets.map(({ name }) => compareNodeLabels(name))

    if (!datasets.length) return {}

    return {
      data,
      labelKeys: label,
      unitKey: unit_key,
    }
  }, [comparedScores])

  const chart2 = useCallback(() => {
    const { labels, datasets } = comparedScores.chart2

    const maturities = datasets
      .map(({ maturity }) => +maturity)
      .sort()
      .reverse()

    const cleaned_maturities = _.uniq(maturities)

    const formattedDatasets = datasets.map(({ label, data, maturity }) => {
      const position = cleaned_maturities.findIndex((m) => m === +maturity)
      const hasFirstPosition = position === 0
      const hasLastPosition = position === cleaned_maturities.length - 1

      return {
        label: compareNodeLabels(label),
        data,
        ...colorByPosition(hasFirstPosition, hasLastPosition, hasOneNode),
      }
    })

    return {
      labels,
      datasets: formattedDatasets,
    }
  }, [comparedScores])

  const dataChart2WebsiteGhgEmissions = [
    comparedScores.chart2_website_only.datasets,
  ].map((datasets) => {
    return {
      label: t(
        `${prefixTranslation}charts.chart2WebsiteOnly.${datasets.label}`
      ),
      data: datasets.data,
    }
  })

  const dataChart2Only1Website = () =>
    comparedScores.chart2_only_1_website.datasets.map((dataset) => {
      return {
        label: t(`score:score.trends.${dataset.label}`),
        data: dataset.data,
        backgroundColor: dataset.background_color,
      }
    })

  const kp1 = (
    <TextChart
      content={
        <TextChartMajor
          content={{
            kpi: compareNodeLabels(comparedScores.kpi1.name),
            kpi_text: t(prefixTranslation + "charts.kpi1.title"),
          }}
        />
      }
    />
  )

  const kpi2 = (
    <TextChart
      content={
        <TextChartMinor
          content={{
            kpi: (
              <>
                {compareNodeLabels(comparedScores.kpi2.name)}
                <br /> {comparedScores.kpi2.delta}
              </>
            ),
            kpi_unit: "%",
            kpi_text: t(
              prefixTranslation + "charts.kpi2.lowest_ghg_emission_node.title"
            ),
            reference: round(comparedScores.kpi2.value.value),
            reference_unit: (
              <>
                &nbsp;
                <Unit
                  unit={NumberFormatterApp.getI18nUnit(
                    EMISSION_UNIT,
                    comparedScores.kpi2.value.unit
                  )}
                />
              </>
            ),
          }}
        />
      }
    />
  )

  const kpi3 = (
    <TextChart
      content={
        <TextChartMinor
          content={{
            kpi: (
              <>
                {compareNodeLabels(comparedScores.kpi3.name)}
                <br />
                {comparedScores.kpi3.delta >= 0 ? "+" : "-"}
                {comparedScores.kpi3.delta}
              </>
            ),
            kpi_text: t(
              prefixTranslation + "charts.kpi3.best_maturity_node.title"
            ),
            reference: `${comparedScores.kpi3.maturity} / 100`,
          }}
        />
      }
    />
  )

  const dataChart4 = comparedScores.chart3.datasets.map((datasets) => {
    const status = datasets.data.statuses.map((status) => {
      return chart3statusColor(status)
    })
    return {
      label: t(
        `score:score.13.chartGHGEmissionsDistribution.${datasets.data.type}`
      ),
      data: datasets.data.emissions.map((value) => round(value)),
      unit: comparedScores.chart3.unit,
      backgroundColor: status,
    }
  })

  const chart2BrokerAssets = useCallback(() => {
    const {
      datasets,
      label_keys,
      unit_key,
      grouping_key,
      domain,
    } = comparedScores.chart2_assets_only

    const convertedLabelKeys = label_keys.map((name) => compareNodeLabels(name))

    const onlyOneItDomainOrEquipmentType = datasets.map((dataset) => {
      return {
        label:
          grouping_key === "equipment_type"
            ? t(`common:equipment_types.${dataset.label}`)
            : dataset.label,
        data: dataset.data,
      }
    })

    const onlyAssetsModule = datasets.map((dataset) => {
      return {
        label: t(`score:score.trends.${dataset.label}`).split(","),
        data: dataset.data,
      }
    })

    return domain === undefined ? (
      <CumulativeBarChartTemplate
        title={t(
          prefixTranslation + "charts.chart2BrokerAssets.chart3_assets.title"
        )}
        datasets={onlyAssetsModule}
        labelKeys={convertedLabelKeys}
        unitPath="units:units.emission"
        unitKey={datasets[0].unit_key}
        helpText={t(
          prefixTranslation + "charts.chart2BrokerAssets.chart3_assets.help"
        )}
        modifiedLegend={COMPARE_LEGENDS}
        showLabelAndUnitsOnBar
        compareBarColors
        brAfterLabel
      />
    ) : (
      <CumulativeBarChartTemplate
        title={
          grouping_key === "equipment_type"
            ? t(
                `${prefixTranslation}charts.chart2BrokerAssets.OnlyAssetsModule.title`,
                {
                  MainItDomain: domain,
                }
              )
            : t(
                `${prefixTranslation}charts.chart2BrokerAssets.OnlyAssetsModule.titleOnlyEquipmentType`
              )
        }
        datasets={onlyOneItDomainOrEquipmentType}
        labelKeys={convertedLabelKeys}
        unitPath="units:units.emission"
        unitKey={unit_key}
        helpText={t(
          prefixTranslation + "charts.chart2BrokerAssets.OnlyAssetsModule.help"
        )}
        compareBarColors
        greyIfOnly1Column
        showLabelAndUnitsOnBar
        brAfterLabel
        modifiedLegend={COMPARE_LEGENDS}
      />
    )
  }, [comparedScores])

  const chart3AssetsBroker = useCallback(() => {
    const {
      datasets,
      label_keys,
      unit_key,
      grouping_key,
      domain,
      main_manufacturer,
      has_mainly_assets,
    } = comparedScores.chart3_assets_only

    const convertedLabelKeys = label_keys.map((name) => compareNodeLabels(name))

    const onlyAssetsModule = datasets.map((dataset) => {
      return {
        label:
          grouping_key === "equipment_type"
            ? t(`common:equipment_types.${dataset.label}`)
            : dataset.label,
        data: dataset.data,
      }
    })

    const mainlyAssetsModule = datasets.map((dataset) => {
      return {
        label: t(`score:score.trends.${dataset.label}`).split(","),
        data: dataset.data,
      }
    })

    return has_mainly_assets ? (
      <CumulativeBarChartTemplate
        title={t(
          prefixTranslation + "charts.chart3BrokerAssets.mainlyAssets.title"
        )}
        datasets={mainlyAssetsModule}
        labelKeys={convertedLabelKeys}
        unitPath="units:units.emission"
        unitKey="t_unit"
        helpText={t(prefixTranslation + "charts.chart3BrokerAssets.help")}
        modifiedLegend={COMPARE_LEGENDS}
        showLabelAndUnitsOnBar
        compareBarColors
        brAfterLabel
        addOtherCategory
      />
    ) : (
      <CumulativeBarChartTemplate
        title={
          grouping_key === "model" || grouping_key === "equipment_type"
            ? t(
                `${prefixTranslation}charts.chart3BrokerAssets.assetsOnly.title`,
                {
                  MainItDomain: main_manufacturer || domain,
                }
              )
            : t(
                `${prefixTranslation}charts.chart3BrokerAssets.only1DomainIt.title`
              )
        }
        datasets={onlyAssetsModule}
        labelKeys={convertedLabelKeys}
        unitPath="units:units.emission"
        unitKey={unit_key}
        helpText={t(prefixTranslation + "charts.chart3BrokerAssets.help")}
        compareBarColors
        modifiedLegend={COMPARE_LEGENDS}
      />
    )
  }, [comparedScores])

  let isWebsiteValueHigher = []

  const dataChart3Website = comparedScores.chart3_website_only.datasets.map(
    (datasets) => {
      return {
        label: datasets.label.name,
        data: datasets.data,
      }
    }
  )

  const labelChart4 = comparedScores.chart3.labels.map((label) => {
    return label.name
  })

  return (
    <ITDomainAnalysisLayoutOrganism
      pillar={PILLAR_ENVIRONMENTAL}
      transSuffix={`${prefixTranslation}charts.`}
      chart1={kp1}
      chart2={kpi2}
      chart3={
        <BarChartTemplate
          title={t(prefixTranslation + "charts.chart1.label")}
          {...chart1()}
          compareBarColors
          unitPath={"units:units.emission"}
          helpText={t(prefixTranslation + "charts.chart1.help")}
        />
      }
      chart4={
        currentNode.has_websites_only ? (
          <RadarChartTemplate
            title={t(prefixTranslation + "charts.chart3_website.title")}
            datasets={dataChart3Website}
            labelPath={prefixTranslation + "charts.chart3_website.labels"}
            labelKeys={[
              "accessibility_maturity",
              "bp_maturity",
              "ghg_maturity",
              "green_hosting_maturity",
              "seo_maturity",
            ]}
            helpText={t(prefixTranslation + "charts.chart3_website.help")}
            gapSize={10}
            chartFill
          />
        ) : currentNode.has_equipment_only ? (
          chart2BrokerAssets()
        ) : (
          <CumulativeBarChartTemplate
            title={t(prefixTranslation + "charts.chart3.label")}
            datasets={dataChart4}
            legendLabels={COMPARE_LEGENDS}
            showLabelOnBar
            labelKeys={labelChart4}
            unitPath="units:units.emission"
            unitKey={comparedScores.chart3.unit}
            helpText={t(prefixTranslation + "charts.chart3.help")}
            useBackgroundColorsInDatasets
            compareBarColors
            modifiedLegend={COMPARE_LEGENDS}
            addOtherCategory
          />
        )
      }
      chart5={kpi3}
      chart6={
        comparedScores.chart3_assets_only.categories ? (
          chart3AssetsBroker()
        ) : currentNode.has_websites_only ? (
          <CompareWebsiteOnlyChar3
            comparedScores={comparedScores}
            currentNode={currentNode}
          />
        ) : isWebsiteValueHigher.every((value) => value === true) ? (
          <RadarChartTemplate
            title={t(prefixTranslation + "charts.chart3_website.title")}
            datasets={dataChart3Website}
            labelPath={prefixTranslation + "charts.chart3_website.labels"}
            labelKeys={[
              "accessibility_maturity",
              "bp_maturity",
              "ghg_maturity",
              "green_hosting_maturity",
              "seo_maturity",
            ]}
            helpText={t(prefixTranslation + "charts.chart3_website.help")}
            gapSize={10}
            chartFill
          />
        ) : (
          <ChartRadarMolecule
            title={t(prefixTranslation + "charts.chart2.label")}
            config={chart2()}
            options={chart4Options}
            pillarId={PILLAR_ENVIRONMENTAL}
          />
        )
      }
    />
  )
}

NodeAnalysesOrganism.propTypes = {
  comparedScores: PropTypes.object.isRequired,
  comparedNodes: PropTypes.array,
}

NodeAnalysesOrganism.defaultProps = {
  disabled: false,
  comparedNodes: [],
}

export default NodeAnalysesOrganism
