import React from "react"
import Config from "assets/configs/score"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import classNames from "classnames"
import { Bar } from "react-chartjs-2"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { yAxes } from "services/charts/yAxes"
import { HIDDEN_LEGEND } from "services/charts/legend"
import { cutLabel } from "views/organisms/canvases/charts/ITDomainAnalysis/WorkplaceBrokerAnalysisOrganism"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

const BrokerManufBreakdown = ({ score, subKey, title }) => {
  const { t } = useTranslation()
  const defaultTitle = t(
    `score:score.13.workplace_broker.ghg_emissions_per_brand.title`
  )
  const { label_keys, datasets, unit_key } = score?.[subKey] || {}

  const getTranslatedLabel = (groupingKey) => {
    return (labelKey) => {
      if (groupingKey !== "equipment_type") return cutLabel(labelKey)

      return t(`common:equipment_types.${labelKey}`)
    }
  }

  const unit = t(`units:units.emission.${unit_key}`)

  const datasetsFormatted = datasets.map((dataset, index) => ({
    label: cutLabel(dataset.label_key),
    data: dataset.data,
    unit,
    backgroundColor: Config.common.colors[index % Config.common.colors.length],
    stack: true,
    showLabelInData: true,
  }))

  datasetsFormatted.sort((a, b) => {
    return (
      b.data.reduce((acc, curr) => acc + curr, 0) -
      a.data.reduce((acc, curr) => acc + curr, 0)
    )
  })

  const data = {
    labels: label_keys.map(getTranslatedLabel("equipment_type")),
    datasets: datasetsFormatted,
  }

  return (
    <HelpWidgetChartInteractiveMolecule
      text={t(`score:score.13.workplace_broker.ghg_emissions_per_brand.help`)}
    >
      <div className={classNames("Verdikt-Score-BarChart", "environmental")}>
        <h1>{title || defaultTitle}</h1>
        <div className="content">
          <Bar
            data={data}
            options={{
              ...DEFAULT_OPTIONS,
              ...yAxes(unit),
              legend: HIDDEN_LEGEND,
            }}
          />
        </div>
      </div>
    </HelpWidgetChartInteractiveMolecule>
  )
}

BrokerManufBreakdown.propTypes = {
  score: PropTypes.object.isRequired,
  subKey: PropTypes.string,
  title: PropTypes.string,
}

BrokerManufBreakdown.defaultProps = {
  subKey: "ghg_emissions_per_brand_chart",
  title: null,
}

export default BrokerManufBreakdown
