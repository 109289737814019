import { useTranslation } from "react-i18next"
import { useNodeContext } from "views/organisms/interactive/NodeContext"
import { ChoiceList } from "@shopify/polaris"
import React from "react"
import {
  CENTRALIZATION_CONSOLIDATION_MODES,
  CENTRALIZATION_METHODOLOGIES,
} from "utils/constants/centralization/type"

const CentralizationChoices = () => {
  const { t } = useTranslation()
  const { node, setNode } = useNodeContext()

  const onChangeMethodology = (value) => {
    node.characteristic.methodology = value[0]
    setNode({ ...node })
  }
  const onChangeConsolidationMode = (value) => {
    node.characteristic.consolidation_mode = value[0]
    setNode({ ...node })
  }

  return (
    <div className="choice-field-radio">
      <ChoiceList
        title={t(`dashboard:modals.methodology.title`)}
        choices={CENTRALIZATION_METHODOLOGIES.map((choice) => ({
          value: choice,
          label: t(`dashboard:modals.methodology.${choice}`),
        }))}
        selected={[node.characteristic.methodology]}
        onChange={onChangeMethodology}
        disabled={node.env_governance_frozen || node.category === "partner"}
      />
      <ChoiceList
        title={t(`dashboard:modals.consolidation_mode.title`)}
        choices={CENTRALIZATION_CONSOLIDATION_MODES.map((choice) => ({
          value: choice,
          label: t(`dashboard:modals.consolidation_mode.${choice}`),
        }))}
        selected={[node.characteristic.consolidation_mode]}
        onChange={onChangeConsolidationMode}
        disabled={node.env_governance_frozen || node.category === "partner"}
      />
    </div>
  )
}

export default CentralizationChoices
