import React, { useCallback } from "react"
import { PropTypes } from "prop-types"
import { useTranslation } from "react-i18next"
import ChartBarStackedGroupedMolecule from "views/molecules/canvases/charts/common/ChartBarStackedGroupedMolecule"
import ITDomainAnalysisLayoutOrganism from "views/organisms/canvases/charts/ITDomainAnalysisLayoutOrganism"
import CompareKpiMolecule from "views/molecules/organization/kpis/CompareKpiMolecule"
import { PILLAR_ENVIRONMENTAL } from "utils/constants/pillars"
import _ from "lodash"
import {
  chart6DatalabelsFormatter,
  Chart6Options,
} from "./HistorizationLabelsHelpers"
import useNodeCharacteristic from "hooks/useNodeCharacteristic"
import CountryApp from "services/apps/countryApp"
import { useAppContext } from "components/AppContext"
import { NODE_TYPE_COUNTRY } from "utils/constants/organization/nodeTypes"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import { Bar } from "react-chartjs-2"
import Config from "assets/configs/score.js"
import ChartMultipleLinesMolecule from "views/molecules/canvases/charts/common/ChartMultipleLinesMolecule"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { yAxes } from "services/charts/yAxes"
import { convertValueToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS, T_CO2E } from "services/units/units"
import { round } from "services/units/round"

const Chart4Options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  legend: {
    position: "bottom",
    display: true,
    fullWidth: true,
    labels: {
      boxWidth: 10,
    },
  },
}

const HistorizationConsolidateAnalyzeChartsOrganism = ({
  score,
  maturityEvolutionChartData,
  ghgEvolutionChartData,
  ghgBreakdownEvolutionChartData,
  maturityDeltaKpi,
  co2DeltaKpi,
  highestMaturityKpi,
}) => {
  const { t } = useTranslation()
  const { getFormattedName } = useNodeCharacteristic()
  const transSuffix = "analyze:historization.consolidation."
  const { currentNode } = useAppContext()

  const forceNoCountry = currentNode?.node_type === NODE_TYPE_COUNTRY

  const chart3 = useCallback(() => {
    const { labels, datasets, unit_key } = ghgEvolutionChartData
    const unit = t(`units:units.emission.${unit_key}`)

    const data = datasets.map((dataset, index) => {
      const country = _.compact(dataset.data)[0].label

      const data = dataset.data.map((d) => d?.value)

      return {
        label: CountryApp.getNameI18n(country),
        label_iso: country,
        backgroundColor: Config.common.colors[index],
        stack: 0,
        data,
        unit,
      }
    })

    return (
      <HelpWidgetChartInteractiveMolecule text={t("score:score.8.chart6.help")}>
        <div className="Verdikt-Score-BarChart">
          <h1>{t(transSuffix + "charts.ghg_evolution.title")}</h1>
          <div className="content">
            <Bar
              data={{ labels: labels, datasets: data }}
              options={{
                ...DEFAULT_OPTIONS,
                ...yAxes(unit),
              }}
            />
          </div>
        </div>
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart2ConvertedDifference = convertValueToReadable(
    Math.abs(co2DeltaKpi.difference),
    T_CO2E,
    CO2_EMISSION_UNITS
  )
  if (co2DeltaKpi.difference < 0) {
    chart2ConvertedDifference.value = -chart2ConvertedDifference.value
  }

  return (
    <ITDomainAnalysisLayoutOrganism
      maturity={score}
      pillar={PILLAR_ENVIRONMENTAL}
      transSuffix={`${transSuffix}charts.`}
      chart1={
        <CompareKpiMolecule
          title={t("analyze:historization.node.kpi.pillar_maturity.title")}
          current={`${maturityDeltaKpi.difference > 0 ? "+" : ""} ${
            maturityDeltaKpi.difference
          }`}
        />
      }
      chart2={
        <CompareKpiMolecule
          title={getFormattedName(co2DeltaKpi.name, co2DeltaKpi.type)}
          subtitle={t(transSuffix + "kpi.biggest_delta_co2.subtitle")}
          current={`${co2DeltaKpi.percentage > 0 ? "+" : ""} ${
            co2DeltaKpi.percentage
          }`}
          difference={round(chart2ConvertedDifference.value)}
          currentUnit="%"
          differenceUnit={t(
            `units:units.emission.${chart2ConvertedDifference.unit}`
          )}
          status={co2DeltaKpi.status}
        />
      }
      chart3={chart3()}
      chart4={
        <ChartMultipleLinesMolecule
          title={t(transSuffix + "charts.maturity_evolution.title")}
          config={maturityEvolutionChartData}
          options={Chart4Options}
          forceNoCountry={forceNoCountry}
        />
      }
      chart5={
        <CompareKpiMolecule
          title={getFormattedName(
            highestMaturityKpi.name,
            highestMaturityKpi.type
          )}
          subtitle={t(transSuffix + "kpi.highest_maturity.subtitle")}
          current={`${highestMaturityKpi.difference > 0 ? "+" : ""} ${
            highestMaturityKpi.difference
          }`}
          status={highestMaturityKpi.status}
        />
      }
      chart6={
        <ChartBarStackedGroupedMolecule
          title={t(transSuffix + "charts.ghg_breakdown_evolution.title")}
          config={ghgBreakdownEvolutionChartData}
          chartType="ghg_breakdown_evolution"
          axesLabel={t(`units:units.emission.t_unit`)}
          datalabelsFormatter={chart6DatalabelsFormatter}
          options={Chart6Options}
          help={t(
            "analyze:historization.node.charts.ghg_breakdown_evolution.help"
          )}
        />
      }
    />
  )
}

export default HistorizationConsolidateAnalyzeChartsOrganism

HistorizationConsolidateAnalyzeChartsOrganism.propTypes = {
  ghgEvolutionChartData: PropTypes.object.isRequired,
  ghgBreakdownEvolutionChartData: PropTypes.object.isRequired,
  maturityEvolutionChartData: PropTypes.object.isRequired,
  maturityDeltaKpi: PropTypes.object.isRequired,
  co2DeltaKpi: PropTypes.object.isRequired,
  highestMaturityKpi: PropTypes.object.isRequired,
  score: PropTypes.number.isRequired,
}
