import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  TextChart,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import Unit from "views/atoms/reports/Unit"
import { convertValueToReadable } from "services/units/valueConversion"
import { CO2_EMISSION_UNITS, T_CO2E } from "services/units/units"
import { round } from "services/units/round"

const TextChartODD13GHGEmissionsAverageMolecule = ({ score }) => {
  const { t } = useTranslation()
  const translationPrefix = "score:score.13."

  let ghg_emissions = score?.odd13_co2_average_equipments || 0

  const convertedValue = convertValueToReadable(
    ghg_emissions,
    T_CO2E,
    CO2_EMISSION_UNITS,
    (value) => round(value)
  )

  const content = {
    kpi: convertedValue.value,
    kpi_unit: <Unit unit={t(`units:units.emission.${convertedValue.unit}`)} />,
    kpi_text: t(`${translationPrefix}chartGHGEmissionsPerEquipment.text`),
  }

  const content_for_display = <TextChartMinor content={content} />
  return <TextChart content={content_for_display} />
}

TextChartODD13GHGEmissionsAverageMolecule.propTypes = {
  score: PropTypes.object.isRequired,
}

export default TextChartODD13GHGEmissionsAverageMolecule
