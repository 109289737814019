import Config from "assets/configs/score.js"

export const KPI_STATUS_UP = "up"
export const KPI_STATUS_NEUTRAL = "neutral"
export const KPI_STATUS_DOWN = "down"
export const KPI_STATUS_DISABLED = "disabled"
export const KPI_STATUS_NEW = "new"

export const KPI_STATUS_GHG_TREND_TO_COLOR = {
  [KPI_STATUS_UP]: Config.common.colorsEvaluation.green,
  [KPI_STATUS_NEUTRAL]: Config.common.colorsEvaluation.yellow,
  [KPI_STATUS_DOWN]: Config.common.colorsEvaluation.red,
  [KPI_STATUS_DISABLED]: Config.common.disabledColor,
  [KPI_STATUS_NEW]: Config.common.colorsEvaluation.blue,
}
