import React from "react"
import { useTranslation } from "react-i18next"
import { Bar } from "react-chartjs-2"
import useTranslatedLabels from "services/charts/translatedLabels"
import { stackedAxes } from "services/charts/axes"
import { getFormattedDatasets } from "services/charts/datasets"
import { DEFAULT_OPTIONS } from "services/charts/default"
import { BOTTOM_LEGEND, HIDDEN_LEGEND } from "services/charts/legend"
import { ChartContainer } from "views/organisms/organization/tables/ChartContainer"
import PropTypes from "prop-types"
import { COLORS } from "utils/constants/colors"
import { DETERMINE_UNITS } from "services/units/units"
import { convertDatasetsToReadable } from "services/units/valueConversion"
import { round } from "services/units/round"

const CumulativeBarChartTemplate = ({
  title,
  datasets,
  labelInDatasetsIsArray,
  labelPath,
  labelKeys,
  unitPath,
  unitKey,
  modifiedLegend,
  firstColumnAlwaysGrey,
  hiddenLegend,
  showLabelAndUnitsOnBar,
  brAfterLabel,
  showLabelOnBar,
  compareBarColors,
  helpText,
  useBackgroundColorsInDatasets,
  fabAndUseCompareBarColors,
  pillar,
  greyIfOnly1Column,
  addOtherCategory,
}) => {
  const { t } = useTranslation()

  let formattedDatasets = getFormattedDatasets({
    datasets,
    fabAndUseCompareBarColors,
    useBackgroundColorsInDatasets,
    labelKeys,
    compareBarColors,
    firstColumnAlwaysGrey,
    greyIfOnly1Column,
    addOtherCategory,
  })

  const translatedLabels = useTranslatedLabels(labelPath, labelKeys)

  let sortedAscDataset

  if (formattedDatasets.length === 1) {
    sortedAscDataset = formattedDatasets.map((dataset) => {
      return {
        ...dataset,
        data: dataset.data.sort((a, b) => b - a),
      }
    })
  } else {
    sortedAscDataset = formattedDatasets.sort((a, b) => b.data[0] - a.data[0])
  }

  const {
    datasets: convertedDatasets,
    unit,
  } = convertDatasetsToReadable(
    sortedAscDataset,
    unitKey,
    DETERMINE_UNITS(unitKey),
    (value) => round(value)
  )

  const totalValueOfDatasets = convertedDatasets.map((dataset) =>
    dataset.data.reduce((acc, value) => acc + value, 0)
  )

  unitKey = unit

  const translatedUnit = unitKey === null ? "" : t(`${unitPath}.${unitKey}`)

  const data = {
    labels: translatedLabels,
    datasets: convertedDatasets,
  }

  const options = {
    ...DEFAULT_OPTIONS,
    legend: modifiedLegend
      ? {
          display: true,
          position: "bottom",
          labels: {
            generateLabels: modifiedLegend,
          },
        }
      : BOTTOM_LEGEND,
    scales: stackedAxes(translatedUnit),
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const label = labelInDatasetsIsArray
            ? data.datasets[tooltipItem.datasetIndex].label[tooltipItem.index]
            : data.datasets[tooltipItem.datasetIndex].label
          return showLabelOnBar
            ? `${label}: ${
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              } ${translatedUnit}`
            : `${label}: ${tooltipItem.yLabel}`
        },
      },
    },
    plugins: {
      datalabels: {
        display: function (context) {
          return (
            (context.dataset.data[context.dataIndex] /
              totalValueOfDatasets[0]) *
              100 >
            5
          )
        },
        formatter: function (value, context) {
          if (showLabelOnBar) {
            return labelInDatasetsIsArray
              ? context.dataset.label[context.dataIndex]
              : context.dataset.label
          } else if (showLabelAndUnitsOnBar) {
            let getUnitPath = unitPath === "" ? "" : translatedUnit
            return `${context.dataset.label}${brAfterLabel ? "\n" : " "}${
              context.dataset.data[context.dataIndex]
            } ${getUnitPath}`
          } else {
            return value
          }
        },
        textAlign: "center",
        color: COLORS.white,
        font: {
          weight: "bold",
        },
        align: showLabelAndUnitsOnBar ? "center" : undefined,
      },
    },
  }

  if (hiddenLegend) {
    options.legend = HIDDEN_LEGEND
  }

  return (
    <ChartContainer title={title} helpText={helpText} pillar={pillar}>
      <Bar data={data} options={options} />
    </ChartContainer>
  )
}

export default CumulativeBarChartTemplate

CumulativeBarChartTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  datasets: PropTypes.array.isRequired,
  labelPath: PropTypes.string,
  labelKeys: PropTypes.array.isRequired,
  unitPath: PropTypes.string.isRequired,
  unitKey: PropTypes.string.isRequired,
  fabAndUseCompareBarColors: PropTypes.bool,
  helpText: PropTypes.string,
  hiddenLegend: PropTypes.bool,
  showLabelAndUnitsOnBar: PropTypes.bool,
  brAfterLabel: PropTypes.bool,
  useBackgroundColorsInDatasets: PropTypes.bool,
  showLabelOnBar: PropTypes.bool,
  compareBarColors: PropTypes.bool,
  firstColumnAlwaysGrey: PropTypes.bool,
  pillar: PropTypes.string,
  modifiedLegend: PropTypes.func,
  labelInDatasetsIsArray: PropTypes.bool,
  greyIfOnly1Column: PropTypes.bool,
  addOtherCategory: PropTypes.bool,
}

CumulativeBarChartTemplate.defaultProps = {
  labelPath: "",
  showLabelAndUnitsOnBar: false,
  brAfterLabel: false,
  useBackgroundColorsInDatasets: false,
  showLabelOnBar: false,
  compareBarColors: false,
  labelInDatasetsIsArray: false,
  firstColumnAlwaysGrey: false,
  hiddenLegend: false,
  fabAndUseCompareBarColors: false,
  addOtherCategory: false,
}
