import CountryApp from "services/apps/countryApp"
import {
  NODE_TYPE_COUNTRY,
  NODE_TYPE_COUNTRY_TEAM,
  NODE_TYPE_REGION,
  NODE_TYPE_SITE
} from "utils/constants/organization/nodeTypes"
import { CONTEXT_TYPE_CORPORATE } from "utils/constants/organization/contextTypes"

export default class NodeOrganization {
  /**
   * @param name {String}
   * @param type {string<Company, Brand, Region, Country, Site>}
   * @param options
   * @returns {string}
   */
  static getName(
    name,
    type,
    options = {
      countryShortName: false,
    }
  ) {
    switch (type) {
      // @todo match the type by a string with the class in lower case
      case "country":
      case "Country":
      case "country_team":
        return CountryApp.getNameI18n(name, options.countryShortName)

      default:
        return name || ""
    }
  }

  /**
   * @description return true if all the children of the same type
   * @param node
   * @returns {boolean}
   */
  static hasOnlyCountriesOrSites(node, isParentColumn = false) {
    if (!isParentColumn && node.children && node.children.length <= 1) {
      return false
    }

    if (node.context !== CONTEXT_TYPE_CORPORATE) return false

    if ([NODE_TYPE_COUNTRY, NODE_TYPE_REGION].includes(node.type)) {
      return true
    }

    return NodeOrganization.nodeHasOnlyChildrenOfCountriesOrSites(node)
  }

  static nodeHasOnlyChildrenOfCountriesOrSites(node) {
    let previousNodeType = null

    if (!node.children) {
      return false
    }

    for (const child of node.children) {
      if (
        previousNodeType !== null &&
        ((child.type !== NODE_TYPE_COUNTRY && child.type !== NODE_TYPE_SITE) ||
          previousNodeType !== child.type)
      ) {
        return false
      }

      previousNodeType = child.type
    }

    return true
  }

  /**
   * @description Generates recursively a 3 digit string to represent the position of the node in the chart and stores the value in datasource.relationship
   * 001 : has no parents, has no siblings, has children
   * 110 : has parents, has siblings, has no children
   * 111 : has parents, has sibilings, has children
   * et caetera...
   * @param {Object} data The datasource object
   * @param {String} flags The digits to increment recursively
   * @returns {Object} The datasource object with a relationship entry containing the flags, for the company and all its children
   */
  static attachRel(node, flags) {
    node.relationship =
      flags + (node.children && node.children.length > 0 ? 1 : 0)
    if (node.children) {
      node.children.forEach(function (item) {
        NodeOrganization.attachRel(
          item,
          "1" + (node.children.length > 1 ? 1 : 0)
        )
      })
    }
    return node
  }

  static getNodeName(node) {
    if (!node) {
      return null
    }

    if (NodeOrganization.IsNodeCountryLevel(node)) {
      return (
        CountryApp.getNameI18n(node.name) ??
        CountryApp.getNameI18n(node.characteristic.name)
      )
    }

    return node.name
  }

  static getNodeNameWithVersion(node) {
    let label = NodeOrganization.getNodeName(node)

    if (node?.version_name) label = `${label} (${node.version_name})`

    return label
  }

  static getNodeClass (
    node,
    hasChildrenBottom,
    selected,
    hasChildrenRight,
    isDiscarded
  ) {
    const hasChildrenBottomClass = hasChildrenBottom ? "children-bottom" : ""
    const hasChildrenRightClass = hasChildrenRight ? "children-right" : ""
    const selectedClass = selected ? "selected" : ""

    const isNodeFullyScored = (node.progress === 100 || node.organization_category !== "corporate") && (node.score || parseFloat(node.odd13_CO2.value))

    const datasourceProgressClass = isNodeFullyScored ? "oc-node--collectClosed" : ""

    const isNotAssignClass = !node.can_access ? "oc-node--not-assign" : ""

    const isDiscardedClass = isDiscarded && (node.score || parseFloat(node.odd13_CO2.value)) ? "oc-node--discarded" : ""

    return [
      "oc-node",
      hasChildrenBottomClass,
      hasChildrenRightClass,
      selectedClass,
      datasourceProgressClass,
      isNotAssignClass,
      `oc-node--${node.organization_category}`,
      isDiscardedClass,
    ].join(" ")
  }

  static isNodeCurrentScope = (node, currentScope) =>
    currentScope && node.id === currentScope.id

  static IsNodeCountryLevel = (node) =>
    [NODE_TYPE_COUNTRY, NODE_TYPE_COUNTRY_TEAM].includes(node.type)

  static findNodeInTree = (condition, ...tree) => {
    return tree?.reduce((acc, node) => {
      if (acc) return acc
      if (condition(node)) return node
      if (node?.children)
        return NodeOrganization.findNodeInTree(condition, ...node?.children)
    }, null)
  }

  static findNodesInTree = (condition, ...tree) => {
    return tree?.reduce((acc, node) => {
      if (condition(node)) acc.push(node)
      if (node?.children)
        acc.push(
          ...NodeOrganization.findNodesInTree(condition, ...node?.children)
        )
      return acc
    }, [])
  }
}
