import { FormLayout } from "@shopify/polaris"
import {
  ExternalLabel,
  TextField,
} from "components/widgets/CollectPage/forms/fields/CollectFields"
import MultipleChoiceField from "components/widgets/CollectPage/forms/fields/MultipleChoiceField"
import Form from "components/widgets/CollectPage/forms/Form"
import React from "react"
import { useTranslation } from "react-i18next"
import UploadOrganism from "views/organisms/upload/UploadOrganism"
import {
  INVENTORY_SOLD_WORKPLACE_EQUIPMENT_INVENTORIES,
  INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES,
} from "utils/constants/centralization/inventoryItems"
import ExternalUploadMolecule from "views/molecules/upload/worplace/ExternalUploadMolecule"
import CentralizedUploadMolecule from "views/molecules/upload/CentralizedUploadMolecule"
import { Module } from "../../Module"
import {
  BYOD_MODULE,
  EQUIPMENT_WORKPLACE_COLLECT_MODULE,
  PRINTING_MODULE,
} from "utils/constants/collects/modules"
import { useFormConfig } from "../../FormContext"
import SnowUploadOrganism from "views/organisms/upload/SnowUploadOrganism"
import { useCollectContext } from "../../../CollectContextProvider"
import i18next from "i18next"
import TemplateFileSelectionMolecule from "views/molecules/files/TemplateFileSelectionMolecule"
import {
  DECENTRALIZED_EXTERNAL_WORKPLACE_FILE_PATHS,
  DECENTRALIZED_EXTERNAL_WORKPLACE_FLEX_FILE_PATHS,
  DECENTRALIZED_WORKPLACE_FILE_PATHS,
  DECENTRALIZED_WORKPLACE_FLEX_FILE_PATHS,
} from "utils/constants/templates"
import { FLEX_INVENTORY_FEATURE, useFeatureFlag } from "hooks/useFeatureFlag"
import TemplateFileLinkMolecule from "views/molecules/files/TemplateFileLinkMolecule"
import { NODE_TYPE_COUNTRY_TEAM } from "utils/constants/organization/nodeTypes"
import { useAppContext } from "components/AppContext"

const fields = [
  "equipment_smartphone_byod_number",
  "equipment_tablet_byod_number",
  "equipment_laptop_byod_number",
  "equipment_desktop_rent_ratio",
  "equipment_desktop_rent_duration",
  "equipment_laptop_rent_ratio",
  "equipment_laptop_rent_duration",
  "equipment_printer_rent_ratio",
  "equipment_printer_rent_duration",
  "equipment_pc_sleep_policy",
  "equipment_printer_sleep_policy",
  "equipment_awareness_policy",
]
const translationPath = "13.workplace"

const Objective13WorkplaceForm = () => {
  const { t } = useTranslation()
  const { collect } = useCollectContext()
  const { ifFeatureActive } = useFeatureFlag()
  const { currentNode, currentRootNode } = useAppContext()

  useFormConfig({ fields, translationPath })

  const filePaths =
    currentNode?.type === NODE_TYPE_COUNTRY_TEAM
      ? DECENTRALIZED_EXTERNAL_WORKPLACE_FILE_PATHS
      : DECENTRALIZED_WORKPLACE_FILE_PATHS

  const flexFilePaths =
    currentNode?.type === NODE_TYPE_COUNTRY_TEAM
      ? DECENTRALIZED_EXTERNAL_WORKPLACE_FLEX_FILE_PATHS
      : DECENTRALIZED_WORKPLACE_FLEX_FILE_PATHS

  return (
    <Form>
      <FormLayout>
        <div className="columns">
          <div className="column">
            <Module module={INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES}>
              <SnowUploadOrganism
                translationPath="13.workplace"
                translationPathName="inventory_equipment_workplace"
                inventoryType={INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES}
              >
                <br />

                <UploadOrganism
                  centralization={collect?.centralization}
                  centralisedComponent={
                    <CentralizedUploadMolecule
                      translationPath="13.workplace"
                      translationPathName="inventory_equipment_workplace"
                      inventoryType={INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES}
                      displayDownloadIcon
                      displayQualityIcon
                    />
                  }
                  decentralisedComponent={
                    <ExternalUploadMolecule
                      translationPathName="inventory_equipment_workplace"
                      fileIdExternal="decentralized_inventory_external_equipment_workplace"
                      fileId="decentralized_inventory_equipment_workplace"
                      inventoryType={INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES}
                      title={t(
                        "collect:questions.13.workplace.inventory_equipment_workplace.label"
                      )}
                      precision={t(
                        "collect:questions.13.workplace.inventory_equipment_workplace.precision"
                      )}
                      displayDownloadIcon
                      displayQualityIcon
                      templateSelectionComponent={ifFeatureActive(
                        FLEX_INVENTORY_FEATURE,
                        <TemplateFileSelectionMolecule
                          fileId="decentralized_inventory_equipment_workplace"
                          structuredFilePath={filePaths[i18next.language]}
                          flexFilePath={flexFilePaths[i18next.language]}
                        />,
                        <TemplateFileLinkMolecule id="decentralized_inventory_equipment_workplace" />
                      )}
                    />
                  }
                />
              </SnowUploadOrganism>
            </Module>
            {currentRootNode.has_sold_inventory_subscription && (
              <Module module={INVENTORY_SOLD_WORKPLACE_EQUIPMENT_INVENTORIES}>
                <SnowUploadOrganism
                  translationPath="13.workplace"
                  translationPathName="sold_inventory_equipment_workplace"
                  inventoryType={INVENTORY_SOLD_WORKPLACE_EQUIPMENT_INVENTORIES}
                >
                  <br />

                  <UploadOrganism
                    centralization={collect?.centralization}
                    centralisedComponent={
                      <CentralizedUploadMolecule
                        translationPath="13.workplace"
                        translationPathName="sold_inventory_equipment_workplace"
                        inventoryType={
                          INVENTORY_SOLD_WORKPLACE_EQUIPMENT_INVENTORIES
                        }
                        displayDownloadIcon
                        displayQualityIcon
                      />
                    }
                    decentralisedComponent={
                      <ExternalUploadMolecule
                        translationPathName="sold_inventory_equipment_workplace"
                        fileIdExternal="decentralized_inventory_external_equipment_sold_workplace"
                        fileId="decentralized_inventory_equipment_sold_workplace"
                        inventoryType={
                          INVENTORY_SOLD_WORKPLACE_EQUIPMENT_INVENTORIES
                        }
                        title={t(
                          "collect:questions.13.workplace.sold_inventory_equipment_workplace.label"
                        )}
                        precision={t(
                          "collect:questions.13.workplace.sold_inventory_equipment_workplace.precision"
                        )}
                        displayDownloadIcon
                        displayQualityIcon
                        templateSelectionComponent={
                          currentNode?.type === NODE_TYPE_COUNTRY_TEAM ? (
                            <TemplateFileLinkMolecule id="decentralized_inventory_external_equipment_sold_workplace" />
                          ) : (
                            <TemplateFileLinkMolecule id="decentralized_inventory_equipment_sold_workplace" />
                          )
                        }
                      />
                    }
                  />
                </SnowUploadOrganism>
              </Module>
            )}
          </div>
          <div className="column">
            <FormLayout>
              {/* BYOD questions */}
              <div>
                <Module module={BYOD_MODULE}>
                  <ExternalLabel translationPathName="shared.byod" />
                  <table>
                    <tbody>
                      <tr>
                        <td>{t("common:equipment_types.smartphone")}</td>
                        <td>
                          <TextField
                            id="equipment_smartphone_byod_number"
                            type="number"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>{t("common:equipment_types.tablet")}</td>
                        <td>
                          <TextField
                            id="equipment_tablet_byod_number"
                            type="number"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>{t("common:equipment_types.laptop")}</td>
                        <td>
                          <TextField
                            id="equipment_laptop_byod_number"
                            type="number"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Module>
              </div>
              {/* Rent questions - Desktop */}
              <Module module={INVENTORY_WORKPLACE_EQUIPMENT_INVENTORIES}>
                <FormLayout.Group
                  title={
                    t("collect:questions.13.workplace.shared.precision") +
                    t(
                      "collect:questions.13.workplace.shared.rent_desktop.label"
                    )
                  }
                >
                  <TextField id="equipment_desktop_rent_ratio" type="number" />
                  <TextField
                    id="equipment_desktop_rent_duration"
                    type="number"
                  />
                </FormLayout.Group>
                {/* Rent questions - Laptop */}
                <FormLayout.Group
                  title={
                    t("collect:questions.13.workplace.shared.precision") +
                    t("collect:questions.13.workplace.shared.rent_laptop.label")
                  }
                >
                  <TextField id="equipment_laptop_rent_ratio" type="number" />
                  <TextField
                    id="equipment_laptop_rent_duration"
                    type="number"
                  />
                </FormLayout.Group>
                {/* Rent questions - Printer */}
                <FormLayout.Group
                  title={
                    t("collect:questions.13.workplace.shared.precision") +
                    t(
                      "collect:questions.13.workplace.shared.rent_printer.label"
                    )
                  }
                >
                  <TextField id="equipment_printer_rent_ratio" type="number" />
                  <TextField
                    id="equipment_printer_rent_duration"
                    type="number"
                  />
                </FormLayout.Group>
              </Module>
              {/* Policy questions */}
              <div>
                <Module module={EQUIPMENT_WORKPLACE_COLLECT_MODULE}>
                  <MultipleChoiceField
                    id="policies"
                    choices={["equipment_pc_sleep_policy"]}
                  />
                </Module>
                <Module module={PRINTING_MODULE}>
                  <MultipleChoiceField
                    id="policies"
                    choices={["equipment_printer_sleep_policy"]}
                    hideLabel
                  />
                </Module>
                <Module module={EQUIPMENT_WORKPLACE_COLLECT_MODULE}>
                  <MultipleChoiceField
                    id="policies"
                    choices={["equipment_awareness_policy"]}
                    hideLabel
                  />
                </Module>
              </div>
            </FormLayout>
          </div>
        </div>
      </FormLayout>
    </Form>
  )
}

export default Objective13WorkplaceForm
