import React from "react"
import { Checkbox as PCheckbox } from "@shopify/polaris"
import { checkHover } from "./Functions"
import PropTypes from "prop-types"
import useFieldIsLocked from "./useFieldIsLocked"
import { useFormContext } from "../FormContext"
import _ from "lodash"

const CheckboxField = ({ id, onChange, disabled, overrideLabel }) => {
  const {
    formData,
    setFormData,
    disabledFields,
    errors,
    translationPath,
  } = useFormContext()
  const { isLocked } = useFieldIsLocked()

  const translationPrefix = `collect:questions.${translationPath}.${id}.`

  return (
    <PCheckbox
      label={overrideLabel || checkHover(translationPrefix)}
      checked={_.get(formData, id)}
      onChange={(value) => {
        if (onChange) return onChange(value)

        const data = _.cloneDeep(formData)
        _.set(data, id, value)

        setFormData(data)
      }}
      error={errors[id]}
      disabled={isLocked || disabled || _.get(disabledFields, id, false)}
    />
  )
}

CheckboxField.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  overrideLabel: PropTypes.string,
}

CheckboxField.defaultProps = {
  overrideLabel: null,
}

export default CheckboxField
