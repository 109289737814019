import Config from "assets/configs/score.js"
import RadarChart from "components/widgets/ScorePage/widgets/RadarChart"
import {
  TextChart,
  TextChartMajor,
  TextChartMinor,
} from "components/widgets/ScorePage/widgets/TextCharts"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import HelpWidgetChartInteractiveMolecule from "views/molecules/interactive/HelpWidgetChartInteractiveMolecule"
import ChartBadge from "views/atoms/badges/ChartBadge"
import ScoreLayoutOrganism from "views/organisms/scores/ScoreLayoutOrganism"
import { COLORS } from "utils/constants/colors"
import AvgCumulativeBarChartTemplate from "views/molecules/charts/template/AvgCumulativeBarChartTemplate"
import RadarChartTemplate from "views/molecules/charts/template/RadarChartTemplate"

const color = (value) => {
  if (value < 33) return COLORS.danger.primary
  if (33 < value && value < 66) return COLORS.warning.primary
  return COLORS.planet.primary
}

const Objective12Score = ({ score, collect }) => {
  const { t } = useTranslation()
  const workplace_equipments = [
    "desktop",
    "laptop",
    "tablet",
    "smartphone",
    "monitor",
  ]
  const translationPrefix = "score:score.12."

  const differentiateLabels = (labels, domains) => {
    const labelCount = labels.reduce((acc, label) => {
      acc[label] = (acc[label] || 0) + 1
      return acc
    }, {})

    return labels.map((label, index) => {
      if (labelCount[label] > 1) {
        return `${label} (${domains[index]})`
      }
      return label
    })
  }

  const chart1 = useCallback(() => {
    const eol_reuse_rate = score["odd12_sustainable_production_reuse_rate"]

    const content = {
      kpi: eol_reuse_rate,
      kpi_unit: t(translationPrefix + "chart1.unit"),
      kpi_text: t(translationPrefix + "chart1.text"),
      translationPrefix_chartX: translationPrefix + "chart1.",
    }

    const content_for_display = <TextChartMajor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart1.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart2 = useCallback(() => {
    const label_maturity = score["odd12_sustainable_consumption_label_maturity"]

    const badge = (
      <ChartBadge value={label_maturity} lowerLimit={30} upperLimit={70} />
    )

    const content = {
      reference: badge,
      kpi: label_maturity,
      kpi_unit: t(translationPrefix + "chart2.unit"),
      kpi_text: t(translationPrefix + "chart2.text"),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart2.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  // version zoom per equipment
  const chart3a = useCallback(() => {
    let datas = []
    let labels = [
      t(translationPrefix + "chart3.second_hand"),
      t(translationPrefix + "chart3.reuse"),
      t(translationPrefix + "chart3.internal_reconditioning"),
      t(translationPrefix + "chart3.external_reconditioning"),
    ]
    let dataset = []

    workplace_equipments.forEach((workplace_equipment) => {
      collect["collected_equipments"].forEach((equipment) => {
        if (workplace_equipment == equipment["equipment_type"]) {
          datas.push(Number(equipment["second_hand_ratio"]) ?? 0)
          datas.push(Number(equipment["internal_reuse_ratio"]) ?? 0)
          datas.push(
            Number(equipment["eol_internal_reconditioning_ratio"]) ?? 0
          )
          datas.push(
            Number(equipment["eol_external_reconditioning_ratio"]) ?? 0
          )

          let object = {
            label: t(`common:equipment_types.${workplace_equipment}`),
            data: { datas },
          }
          dataset.push(object)
          datas = []
        }
      })
    })

    let data = [
      {
        labels: labels,
        datasets: dataset,
      },
    ]

    return (
      <RadarChart title={t(translationPrefix + "chart3.title")} data={data} />
    )
  }, [score])

  // version zoom per end of life
  const chart3 = useCallback(() => {
    const translatedLabels = score.second_hand_vs_reuse.labels.map((label) =>
      t(`common:equipment_types.${label}`)
    )

    const translatedDomains = score.second_hand_vs_reuse.domains.map((domain) =>
      t(`common:domains.${domain}`)
    )

    const labels = differentiateLabels(translatedLabels, translatedDomains)

    score.second_hand_vs_reuse.datasets.forEach((dataset) => {
      dataset.label = t(`score:score.12.chart3.${dataset.label_key}`)
    })

    const data = score.second_hand_vs_reuse.datasets.map((dataset, index) => {
      const pointColor = (index, data) => {
        return data.map(color)
      }
      const pointBackgroundColors = pointColor(index, dataset.data.datas)
      return {
        label: dataset.label,
        data: dataset.data.datas,
        pointBackgroundColor: pointBackgroundColors,
      }
    })

    return (
      <RadarChartTemplate
        title={t(translationPrefix + "chart3.title")}
        datasets={data}
        labelKeys={labels}
        chartFill
        usePointBackgroundColorInDatasets
        helpText={t(translationPrefix + "chart3.help")}
      />
    )
  }, [score])

  const chart4 = useCallback(() => {
    const {
      datasets,
      labels,
      references,
      domains,
    } = score.odd12_collected_equipments

    const dataset = {
      datasets: datasets.map((dataset) => {
        return {
          data: dataset.data,
          label: t(translationPrefix + `${dataset.label}`),
          backgroundColor: dataset.backgroundColor,
        }
      }),
    }

    const translatedLabels = labels.map((label, index) => {
      const equipmentType = label[1]
      const hasSameEquipmentType =
        labels.filter((label) => label[1] === equipmentType).length > 1

      return !hasSameEquipmentType
        ? t(`common:equipment_types.${label[1]}`)
        : `${t(`common:equipment_types.${label[1]}`)} (${t(`common:domains.${domains[index]}`)})`
    })

    return (
      <AvgCumulativeBarChartTemplate
        title={t(translationPrefix + "chart4.title")}
        datasets={dataset.datasets}
        useBackgroundColorsInDatasets
        referenceData={references}
        labelKeys={translatedLabels}
        unitPath={`${translationPrefix}chart4`}
        unitKey={"data_title"}
        helpText={t(translationPrefix + "chart4.help")}
      />
    )
  }, [score])

  const chart5 = useCallback(() => {
    const eolRecycle =
      score["odd15_electronic_waste_non_recycled_ratio"] !== null
        ? 100 - score["odd15_electronic_waste_non_recycled_ratio"]
        : t(`score:score.12.chart5.not_available`)
    const badge = (
      <ChartBadge value={eolRecycle} lowerLimit={80} upperLimit={95} />
    )

    const content = {
      reference: badge,
      kpi: eolRecycle,
      kpi_unit:
        eolRecycle !== "N/A" ? t(translationPrefix + "chart5.unit") : "",
      kpi_text: t(translationPrefix + "chart5.text"),
    }

    const content_for_display = <TextChartMinor content={content} />

    return (
      <HelpWidgetChartInteractiveMolecule
        text={t(translationPrefix + "chart5.help")}
      >
        <TextChart content={content_for_display} />
      </HelpWidgetChartInteractiveMolecule>
    )
  }, [score])

  const chart6 = useCallback(() => {
    const endOfLife = score.end_of_life
    const translatedLabels = endOfLife.labels.map((label) =>
      t(`common:equipment_types.${label}`)
    )

    const translatedDomains = endOfLife.domains.map((domain) =>
      t(`common:domains.${domain}`)
    )

    const labels = differentiateLabels(translatedLabels, translatedDomains)

    endOfLife.datasets.forEach((dataset) => {
      dataset.label = t(`score:score.12.chart6.${dataset.label_key}`)
      dataset.backgroundColor =
        Config["12"].chart6[dataset.label_key].backgroundColor

      const color = Config["12"].chart6[dataset.label_key].borderColor
      dataset.borderColor = color
      dataset.pointBackgroundColor = Array(dataset.data.datas.length).fill(
        color
      )
    })

    const data = endOfLife.datasets.map((dataset) => {
      return {
        label: dataset.label,
        data: dataset.data.datas,
        backgroundColor: dataset.backgroundColor,
        borderColor: dataset.borderColor,
        pointBackgroundColor: dataset.pointBackgroundColor,
      }
    })

    return (
      <RadarChartTemplate
        title={t(translationPrefix + "chart6.title")}
        datasets={data}
        gapSize={10}
        labelKeys={labels}
        chartFill
        useBackgroundColorsInDatasets
        useBorderColorInDatasets
        usePointBackgroundColorInDatasets
        helpText={t(translationPrefix + "chart6.help")}
      />
    )
  }, [score])

  return (
    <ScoreLayoutOrganism
      maturity={score.odd12_maturity ?? 0}
      chart1={chart1()}
      chart2={chart2()}
      chart3={chart3()}
      chart4={chart4()}
      chart5={chart5()}
      chart6={chart6()}
    />
  )
}

Objective12Score.propTypes = {
  score: PropTypes.object.isRequired,
  collect: PropTypes.object.isRequired,
}

export default Objective12Score
