import React from "react"
import { Button, Icon, Stack } from "@shopify/polaris"
import { CirclePlusMajor } from "@shopify/polaris-icons"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const EnergyConsumptionTableEmptyBody = ({ isLocked, setOpen, colSpan }) => {
  const { t } = useTranslation()

  return (
    <tr key="0">
      <td colSpan={colSpan}>
        <Stack distribution="center" alignment="center" spacing="extraTight">
          <p>
            {t(
              "collect:questions.7.energy_consumption.energy_consumption_inventories.empty"
            )}
          </p>
          {!isLocked && (
            <Button plain onClick={() => setOpen(true)}>
              <Icon source={CirclePlusMajor} />
            </Button>
          )}
        </Stack>
      </td>
    </tr>
  )
}

EnergyConsumptionTableEmptyBody.propTypes = {
  isLocked: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  colSpan: PropTypes.number.isRequired,
}

EnergyConsumptionTableEmptyBody.defaultProps = {
  isLocked: false,
  formData: null,
}

export default EnergyConsumptionTableEmptyBody
